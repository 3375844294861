export const formatListToString = (list: string[]): string => {
  let formatedStr = '{';
  const keyLength = list.length;
  list.forEach((key: string, idx: number) => {
    formatedStr += key;
    if (idx < keyLength - 1) {
      formatedStr += ',';
    }
  });
  formatedStr += '}';

  return formatedStr;
};
