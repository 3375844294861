import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';
import { Transportations } from 'utils/hasura';

import { GraphqlService } from 'services/graphql';

const { client: graphqlClient } = GraphqlService;

const GET_VEHICLE_TYPES = gql`
  query GetVehicleTypes {
    transportations(
      distinct_on: [vehicle_type]
      where: { vehicle_type: { _is_null: false } }
    ) {
      vehicle_type
    }
  }
`;

export function useVehicleTypes(): string[] {
  const [vehicleTypes, setVehicleTypes] = useState<string[]>([]);

  useEffect(() => {
    graphqlClient
      .request<{
        transportations: Transportations[];
      }>(GET_VEHICLE_TYPES)
      .then(({ transportations }) => {
        setVehicleTypes(
          transportations
            .map(item => item.vehicle_type || '')
            .filter(item => !!item)
        );
      })
      .catch(err => Promise.reject(err.message));
  }, []);

  return vehicleTypes;
}
