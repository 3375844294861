import { Option } from '@pankod/refine-core';

const GUIDE_LANGUAGES = ['French', 'German', 'English', 'Spanish'];

export function buildGuideLanguageOptions(
  guideLanguages: string[] = GUIDE_LANGUAGES
): Option[] {
  return guideLanguages
    .filter(item => !!item)
    .map(item => ({
      value: item,
      label: item
    }));
}
