import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';
import { ProviderServers } from 'utils/hasura';

import { GraphqlService } from 'services/graphql';
import { Country } from '../models/country';

const { client: graphqlClient } = GraphqlService;

export function useCountries(): Country[] {
  const GET_COUNTRIES = gql`
    query GetCountries {
      provider_servers {
        country
        country_name
      }
    }
  `;

  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    graphqlClient
      .request<{
        provider_servers: ProviderServers[];
      }>(GET_COUNTRIES)
      .then(({ provider_servers }) => {
        setCountries(
          provider_servers
            .map(item => ({
              code: item.country || '',
              name: item.country_name || ''
            }))
            .filter(item => item.name && item.code)
        );
      })
      .catch(err => Promise.reject(err.message));
  }, []);

  return countries;
}
