import dataProvider from '@pankod/refine-hasura';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as gql from 'gql-query-builder';
import { GraphqlService } from 'services/graphql';

import { formatListToString } from '../utils';

const { client: graphqlClient } = GraphqlService;

export const locationsDataProvider = dataProvider(graphqlClient);

const { update } = locationsDataProvider;

locationsDataProvider.getOne = async ({ resource, id, metaData }) => {
  const operation = `${metaData?.operation ?? resource}_by_pk`;

  const { query, variables } = gql.query({
    operation,
    variables: {
      id: { value: id, type: 'Int', required: true },
      ...metaData?.variables
    },
    fields: metaData?.fields
  });

  const response = await graphqlClient.request(query, variables);

  const formatedLocation = response[operation];

  formatedLocation.categories = formatedLocation.categories || [];
  formatedLocation.tag_keys = formatedLocation.tag_keys || [];

  if (formatedLocation.geometry && formatedLocation.geometry.coordinates) {
    formatedLocation.geometry.coordinates = JSON.parse(
      formatedLocation.geometry.coordinates
    );
  }
  return {
    data: formatedLocation
  };
};

locationsDataProvider.deleteOne = async ({ resource, id, metaData }) => {
  const deleteOperation = metaData?.operation ?? resource;
  const softDeleteOperation = `update_${deleteOperation}_by_pk`;

  const { query, variables } = gql.mutation({
    operation: softDeleteOperation,
    variables: {
      pk_columns: {
        value: { id },
        type: `${deleteOperation}_pk_columns_input`,
        required: true
      },
      _set: {
        type: `${deleteOperation}_set_input`
      },
      ...metaData?.variables
    },
    fields: metaData?.fields ?? ['id']
  });

  const response = await graphqlClient.request(query, {
    ...variables,
    _set: {
      deleted_at: new Date()
    }
  });

  return {
    data: response[softDeleteOperation]
  };
};

locationsDataProvider.update = async ({
  resource,
  metaData,
  variables,
  id
}) => {
  const operation = metaData?.mutation?.operation;

  if (!operation) {
    const cloneVariable: any = variables;
    if (cloneVariable.tag_keys) {
      const formatedStr = formatListToString(cloneVariable.tag_keys);
      cloneVariable.tag_keys = formatedStr;
    }
    if (cloneVariable.categories) {
      const formatedStr = formatListToString(cloneVariable.categories);
      cloneVariable.categories = formatedStr;
    }
    // This field just read, not update
    delete cloneVariable.place_parents;
    return update({ resource, metaData, variables: cloneVariable, id });
  }

  const metaDataVariables = metaData?.mutation?.variables;
  const [input] = Object.keys(metaDataVariables || {});

  const { query } = gql.mutation({
    operation,
    variables: metaDataVariables,
    fields: metaData?.fields
  });

  const response = await graphqlClient.request(query, {
    [input]: { ...variables, id: parseInt(id as string, 10) }
  });

  return {
    data: response[operation]
  };
};
