import dataProvider from '@pankod/refine-hasura';
import { GraphQLClient } from 'graphql-request';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as gql from 'gql-query-builder';

export const commonProvider = (graphqlClient: GraphQLClient) => {
  const gqlDataProvider = dataProvider(graphqlClient);

  const { update, create } = gqlDataProvider;

  gqlDataProvider.getOne = async ({ resource, id, metaData }) => {
    const operation = `${metaData?.operation ?? resource}_by_pk`;

    const { query, variables } = gql.query({
      operation,
      variables: {
        id: { value: id, type: 'Int', required: true },
        ...metaData?.variables
      },
      fields: metaData?.fields
    });

    const response = await graphqlClient.request(query, variables);

    return {
      data: response[operation]
    };
  };

  gqlDataProvider.create = async ({ resource, metaData, variables }) => {
    const createOperation = metaData?.mutation?.operation;
    if (createOperation) {
      const metaDataVariables = metaData?.mutation?.variables;
      const [input] = Object.keys(metaDataVariables || {});

      const { query } = gql.mutation({
        operation: createOperation,
        variables: metaDataVariables,
        fields: metaData?.fields
      });

      const response = await graphqlClient.request(query, {
        [input]: variables
      });

      return {
        data: response[createOperation]
      };
    }
    return create({ resource, metaData, variables });
  };

  gqlDataProvider.update = async ({ resource, metaData, variables, id }) => {
    const operation = metaData?.mutation?.operation;

    if (!operation) {
      return update({ resource, metaData, variables, id });
    }

    const metaDataVariables = metaData?.mutation?.variables;
    const [input] = Object.keys(metaDataVariables || {});

    const { query } = gql.mutation({
      operation,
      variables: metaDataVariables,
      fields: metaData?.fields
    });

    const response = await graphqlClient.request(query, {
      [input]: { ...variables, id: parseInt(id as string, 10) }
    });

    return {
      data: response[operation]
    };
  };

  gqlDataProvider.deleteOne = async ({ resource, id, metaData }) => {
    // Soft delete
    const operation = metaData?.operation ?? resource;
    const softDeleteOperation = `update_${operation}_by_pk`;

    const { query, variables } = gql.mutation({
      operation: softDeleteOperation,
      variables: {
        pk_columns: {
          value: { id },
          type: `${operation}_pk_columns_input`,
          required: true
        },
        _set: {
          type: `${operation}_set_input`
        },
        ...metaData?.variables
      },
      fields: metaData?.fields ?? ['id']
    });

    const response = await graphqlClient.request(query, {
      ...variables,
      _set: {
        deleted_at: new Date()
      }
    });

    return {
      data: response[softDeleteOperation]
    };
  };

  return gqlDataProvider;
};
