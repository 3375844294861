import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { IconButton, Tooltip } from '@mui/material';
import { useTable } from '@pankod/refine-antd';
import { HttpError, MetaDataQuery } from '@pankod/refine-core';
import { ListPageLayout } from 'components/layout/ListPageLayout';
import { COMMON_DATE_FORMAT, COMMON_TIME_FORMAT } from 'constants/common';
import dayjs from 'dayjs';
import { uniq } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { buildTableColumn } from 'utils/buildTableColumn';
import { SyncStatuses } from 'utils/hasura';
import { formatDuration } from 'utils/parseDateTime';

export const SyncStatusList: React.FC<any> = () => {
  const { t } = useTranslation();

  const { tableProps, searchFormProps } = useTable<
    SyncStatuses,
    HttpError,
    {
      function_name: string;
      status: string;
      created_at?: string;
      updated_at?: string;
    }
  >({
    resource: 'sync_statuses',
    initialSorter: [
      {
        field: 'id',
        order: 'desc'
      }
    ],
    hasPagination: true,
    initialPageSize: 6,
    metaData: {
      fields: [
        'id',
        'function_name',
        'status',
        'created_at',
        'updated_at',
        'durations',
        'provider_servers',
        'organizations'
      ]
    },
    liveMode: 'auto',

    liveParams: {
      meta: {
        operation: 'sync_statuses'
      } as MetaDataQuery,
      subscriptionType: 'useList'
    }
  });

  const buildOrganizationText = useCallback((orgs: any) => {
    if (typeof orgs !== 'string') return '';

    const organizations = JSON.parse(orgs) || [];
    const organizationNames = organizations.map(
      ({ organizationName }: { organizationName: string }) => organizationName
    );

    return uniq(organizationNames).join(', \n');
  }, []);

  const buildCountriesText = useCallback((countriesStr: any) => {
    if (typeof countriesStr !== 'string') return '';

    const countries = JSON.parse(countriesStr) || [];
    const organizationNames = countries.map(
      ({ countryName }: { countryName: string }) => countryName
    );

    return uniq(organizationNames).join(', \n');
  }, []);

  const buildDurationsText = useCallback(
    (duration: number) => formatDuration(duration),
    []
  );

  tableProps.columns = [
    buildTableColumn(
      t('common:sync.type'),
      'function_name',
      false,
      (_, record) => <span>{record.function_name}</span>
    ),
    buildTableColumn(
      t('common:sync.started-at'),
      'created_at',
      false,
      (_, record) => (
        <span style={{ paddingRight: '30px', whiteSpace: 'pre' }}>
          {dayjs(record.created_at).format(
            `${COMMON_DATE_FORMAT} ${COMMON_TIME_FORMAT}`
          )}
        </span>
      )
    ),
    buildTableColumn(
      t('common:sync.updated-at'),
      'updated_at',
      false,
      (_, record) => (
        <span style={{ paddingRight: '30px', whiteSpace: 'pre' }}>
          {record.updated_at
            ? dayjs(record.updated_at).format(
                `${COMMON_DATE_FORMAT} ${COMMON_TIME_FORMAT}`
              )
            : null}
        </span>
      )
    ),
    buildTableColumn(t('common:sync.status'), 'status', false, (_, record) => (
      <span>{record.status}</span>
    )),
    buildTableColumn(
      t('common:sync.durations'),
      'durations',
      false,
      (_, record) => (
        <span style={{ paddingRight: '30px', whiteSpace: 'pre' }}>
          {buildDurationsText(record.durations || 0)}
        </span>
      )
    ),
    buildTableColumn(
      t('common:sync.countryName'),
      'provider_servers',
      false,
      (_, record) => {
        const textCountries = buildCountriesText(record.provider_servers || 0);
        return (
          <div>
            <span style={{ whiteSpace: 'pre' }}>
              {textCountries?.split(',')?.[0] || 'All countries'}
            </span>
            {textCountries?.split(',').length > 1 && (
              <Tooltip
                placement="left"
                title={
                  <p style={{ fontSize: '14px' }}>
                    {textCountries || 'All countries'}
                  </p>
                }
              >
                <IconButton>
                  <MoreHoriz />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      }
    ),
    buildTableColumn(
      t('common:sync.organization'),
      'organizations',
      false,
      (_, record) => {
        const textOrganizations = buildOrganizationText(
          record.organizations || 0
        );
        return (
          <div>
            <span style={{ whiteSpace: 'pre' }}>
              {textOrganizations.split(',')?.[0] || 'All organizations'}
            </span>
            {textOrganizations.split(',')?.length > 1 && (
              <Tooltip
                placement="left"
                title={
                  <p style={{ fontSize: '14px' }}>
                    {textOrganizations || 'All organizations'}
                  </p>
                }
              >
                <IconButton>
                  <MoreHoriz />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      }
    )
  ];

  return (
    <ListPageLayout tableProps={tableProps} searchFormProps={searchFormProps} />
  );
};
