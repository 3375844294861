import { FormLabel, Typography } from '@mui/material';
import React from 'react';

interface ISyncItemLabelProps {
  label: string;
}

export const SyncItemLabel: React.FC<ISyncItemLabelProps> = ({
  label
}: ISyncItemLabelProps) => (
  <FormLabel className="flex mb-2 !pb-2">
    <Typography variant="subtitle2" className="text-sm font-medium">
      {label}
    </Typography>
  </FormLabel>
);
