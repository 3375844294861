import React from 'react';
import routerProvider from '@pankod/refine-react-router-v6';
import { TitleProps } from '@pankod/refine-core';

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <div
    style={{
      textAlign: 'center'
    }}
  >
    <Link to="/">
      {collapsed ? (
        <img
          src={'/images/svg/logo.svg'}
          alt="ExoCreate"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px 24px'
          }}
        />
      ) : (
        <img
          src={'/images/svg/logo.svg'}
          alt="ExoCreate"
          style={{
            width: '150px',
            padding: '12px 24px'
          }}
        />
      )}
    </Link>
  </div>
);
