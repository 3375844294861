import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  SelectProps
} from '@pankod/refine-antd';
import { SelectOption } from 'models/form/select';
import { useRef } from 'react';

import styles from './index.module.scss';

export interface TitleFilterProps {
  title: string;
  name: string;
  filterType?: 'number' | 'string' | 'dropdown';
  options?: SelectOption[];
  selectProps?: SelectProps<SelectOption>;
}

export const TitleFilter: React.FC<TitleFilterProps> = ({
  filterType,
  title,
  name,
  options,
  selectProps
}: TitleFilterProps) => {
  let Control: JSX.Element;
  const refSubmit = useRef<HTMLButtonElement>(null);
  const selectOptionsProps = options?.length ? { options } : selectProps;

  switch (filterType) {
    case 'number':
      Control = (
        <InputNumber className={styles.formNumberInput} placeholder={title} />
      );
      break;

    case 'string':
      Control = <Input placeholder={title} />;
      break;

    case 'dropdown':
      Control = (
        <Select
          allowClear
          {...selectOptionsProps}
          placeholder={title}
          onChange={() => {
            refSubmit.current?.click();
          }}
          getPopupContainer={trigger => trigger.parentNode}
        />
      );

      break;

    default:
      Control = <div>{title}</div>;
  }

  return (
    <div
      className={styles.titleFilter}
      onClick={e => {
        e.stopPropagation();
      }}
      aria-hidden="true"
    >
      <div>{title}</div>

      <Form.Item name={name} className={styles.formFilterItem}>
        {Control}
      </Form.Item>
      <Button ref={refSubmit} htmlType="submit" type="primary" hidden />
    </div>
  );
};

TitleFilter.defaultProps = {
  options: [],
  selectProps: {},
  filterType: 'string'
};
