import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';
import { Products } from 'utils/hasura';

import { GraphqlService } from 'services/graphql';

import { IDestinationType } from '../models/product';

const { client: graphqlClient } = GraphqlService;

const GET_PRODUCT_DESTINATION = gql`
  query GetProductTourCodes {
    products(
      distinct_on: [address4]
      where: { address4: { _is_null: false } }
    ) {
      address4
    }
  }
`;

export function useDestinations(): IDestinationType[] {
  const [productDestination, setProductDestination] = useState<
    IDestinationType[]
  >([]);

  useEffect(() => {
    graphqlClient
      .request<{
        products: Products[];
      }>(GET_PRODUCT_DESTINATION)
      .then(({ products }) => {
        setProductDestination(
          products
            .map(item => ({
              destination: item.address4 || ''
            }))
            .filter(item => !!item.destination)
        );
      })
      .catch(err => Promise.reject(err.message));
  }, []);

  return productDestination;
}
