/**
 * Transform filter format
 * From { parent.child: 'value' } to { parent: { child: 'value' } }
 */
export function formatMutationInput(
  payload: Record<string, any>
): Record<string, any> | null {
  if (!payload) {
    return null;
  }

  const filterQuery = Object.keys(payload).reduce((prev: any, curr) => {
    const fieldInputs = curr.split('.');
    const lastIndex = fieldInputs.length - 1;
    const value = { ...prev };

    let lastFieldValue = value;

    fieldInputs.forEach((field, index) => {
      if (index === lastIndex) {
        lastFieldValue[field] = payload[curr];

        return;
      }

      Object.assign(lastFieldValue, { [field]: lastFieldValue[field] || {} });
      lastFieldValue = lastFieldValue[field];
    });

    return value;
  }, {});

  return filterQuery;
}
