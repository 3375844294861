import {
  Col,
  Edit,
  Form,
  Input,
  ListButton,
  Row,
  Select,
  Switch,
  Tabs,
  useForm
} from '@pankod/refine-antd';
import { DestinationSelect } from 'components/DestinationSelect';
import { ImageUpload } from 'components/ImageUpload';
import { MapBox } from 'components/MapBox';
import { OrganizationCheckboxList } from 'components/OrganizationCheckboxList';
import { ProductTypeSelect } from 'components/ProductTypeSelect';
import { TextEditor } from 'components/TextEditor';
import { TourCodesSelect } from 'components/TourCodesSelect';
import { useCountries } from 'hooks/useCountries';
import { LocationInputsTypes } from 'models';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkLocationIsValid } from 'utils';
import { buildCountrySelectOptions } from 'utils/buildCountrySelectOptions';
import { Tours } from 'utils/hasura';

export interface IOption {
  value: string | any;
  label: string;
  isDisabled?: boolean;
}

export const TourEdit: React.FC = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const countries = useCountries();
  const [startLocation, setStartLocation] = useState('');
  const [endLocation, setEndLocation] = useState('');

  const availableTime = useMemo(() => {
    const start = moment().startOf('day');
    const end = moment().endOf('day');
    const options: IOption[] = [];

    while (start.isBefore(end)) {
      options.push({
        label: start.format('LT'),
        value: start.format('HH:mm')
      });

      start.add('15', 'minute');
    }

    return options;
  }, []);

  const { formProps, saveButtonProps, queryResult, form } = useForm<Tours>({
    dataProviderName: 'toursDataProvider',
    metaData: {
      mutation: {
        operation: 'products'
      },
      fields: [
        'name',
        'address4',
        'address5',
        'locality',
        'included_breakfast',
        'included_lunch',
        'included_dinner',
        'code6',
        'code6_description',
        'description',
        'images',
        {
          tour: [
            'id',
            'start_location',
            'end_location',
            'start_time',
            'end_time',
            'xhr',
            'guide'
          ]
        }
      ]
    },
    redirect: 'list'
  });

  useEffect(() => {
    if (queryResult?.isLoading) return;
    const { tour } = queryResult?.data?.data as any;
    setStartLocation(tour?.start_location);
    setEndLocation(tour?.end_location);
  }, [queryResult?.isLoading]);

  const setLocationChangValue = (type: string, value: string) => {
    switch (type) {
      case LocationInputsTypes.STARTLOCATION:
        setStartLocation(value);
        form.setFields([{ name: ['tour', 'start_location'], value }]);
        break;
      case LocationInputsTypes.ENDLOCATION:
        setEndLocation(value);
        form.setFields([{ name: ['tour', 'end_location'], value }]);
        break;
      default:
        break;
    }
  };
  return (
    <Edit
      saveButtonProps={{ ...saveButtonProps, disabled: true }}
      pageHeaderProps={{ extra: <ListButton /> }}
    >
      <Form {...formProps} layout="vertical">
        <Tabs type="card">
          <TabPane tab={t('pages.organizationEdit.generalInformation')} key="1">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label={t('pages.organizationEdit.title')}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('form.fields.errors.name.required')
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('pages.organizationEdit.alternativeTitle')}
                  name={['tour', 'xhr']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label={t('pages.organizationEdit.locality')}
                  name="locality"
                  rules={[
                    {
                      required: true,
                      message: t('form.fields.errors.name.required')
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label={t('pages.organizationEdit.startTime')}
                  name={['tour', 'start_time']}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    options={availableTime}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('pages.organizationEdit.endTime')}
                  name={['tour', 'end_time']}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    options={availableTime}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label={t('pages.organizationEdit.country')}
                  name="address5"
                >
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    options={buildCountrySelectOptions(countries)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <DestinationSelect form={form} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TourCodesSelect form={form} />
              </Col>
              <Col span={12}>
                <ProductTypeSelect form={form} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  label={t('pages.organizationEdit.includedBreakfast')}
                  name="included_breakfast"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('pages.organizationEdit.includedLunch')}
                  name="included_lunch"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('pages.organizationEdit.includedDinner')}
                  name="included_dinner"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t('pages.organizationEdit.description')} key="2">
            <TextEditor name="description" />
          </TabPane>
          <TabPane tab={t('pages.organizationEdit.images')} key="5">
            <Form.Item name="images">
              <ImageUpload />
            </Form.Item>
          </TabPane>
          <TabPane tab={t('pages.organizationEdit.map')} key="6">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label={t('pages.organizationEdit.startLocation')}
                  name={['tour', 'start_location']}
                >
                  <Input
                    value={startLocation}
                    onChange={e => setStartLocation(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('pages.organizationEdit.endLocation')}
                  name={['tour', 'end_location']}
                >
                  <Input
                    value={endLocation}
                    onChange={e => setEndLocation(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <MapBox
              position={[
                {
                  key: 'S',
                  location: checkLocationIsValid(startLocation)
                },
                {
                  key: 'E',
                  location: checkLocationIsValid(endLocation)
                }
              ]}
              widthMap={'auto'}
              heightMap={400}
              edit
              setLocationInputs={setLocationChangValue}
            />
          </TabPane>
          <TabPane tab={t('pages.organizationEdit.agencies')} key="7">
            <OrganizationCheckboxList />
          </TabPane>
        </Tabs>
      </Form>
    </Edit>
  );
};
