/* eslint-disable function-paren-newline */
import { FileUploadResponse, UploadType } from 'utils/hasura';

import { FILE_UPLOAD } from 'graphql-queries';
import { Image } from 'models/imageUpload';
import { GraphqlService } from 'services/graphql';

const { client: graphqlClient } = GraphqlService;

export async function handleImageUpload(
  images: Image[],
  uploadType: UploadType
): Promise<string[]> {
  if (!images?.length) {
    return [];
  }

  let newImages: Image[] = [];
  const existingImages: Image[] = [];

  images.forEach(image => {
    if (image.status === 'removed') {
      return;
    }

    if (image.lastModified) {
      newImages.push(image);
      return;
    }

    existingImages.push(image);
  });

  newImages = await Promise.all(
    newImages.map(image => {
      const [, base64Only] = image.thumbUrl.split(',');

      return {
        ...image,
        thumbUrl: base64Only
      };
    })
  );

  const existingImageUrls = existingImages.map(image => image.thumbUrl);

  const imageUploadList = newImages.map(image => ({
    base64str: image.thumbUrl,
    name: image.name,
    type: image.type
  }));

  const {
    fileUpload: { bucketUrl, files }
  } = await graphqlClient.request<{
    fileUpload: FileUploadResponse;
  }>(FILE_UPLOAD, {
    files: imageUploadList,
    upload_type: uploadType
  });

  const newImageUrls = files.map(
    (file: { key: string }) => `${bucketUrl}${file.key}`
  );

  return [...existingImageUrls, ...newImageUrls];
}
