import dataProvider from '@pankod/refine-hasura';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as gql from 'gql-query-builder';
import { GraphqlService } from 'services/graphql';

import { COMMON_DATE_FORMAT } from 'constants/common';
import dayjs from 'dayjs';
import { camelCase, flow, get, map, mapKeys } from 'lodash/fp';

const { client: graphqlClient } = GraphqlService;

export const locationsFYIDataProvider = dataProvider(graphqlClient);

locationsFYIDataProvider.getList = async ({ metaData, pagination }) => {
  const { query, variables } = gql.query({
    operation: 'getFYIMessagesByLocationId',
    variables: {
      input: {
        type: 'GetFYIMessagesByLocationIdInput',
        required: true,
        value: {
          locationId: metaData?.locationId,
          limit: pagination?.pageSize,
          offset:
            (get('current')(pagination) - 1) * get('pageSize')(pagination),
          isActive: true
        }
      }
    },
    fields: metaData?.fields
  });

  const response = await graphqlClient.request(query, variables);

  const normalizedData = flow(map(mapKeys(camelCase)))(
    response.getFYIMessagesByLocationId.data
  );

  return {
    data: normalizedData,
    total: response.getFYIMessagesByLocationId.count
  };
};

locationsFYIDataProvider.create = async ({
  metaData,
  variables: inputVariables
}) => {
  const cloneVariable: any = inputVariables;

  const { query, variables } = gql.mutation({
    operation: 'createFYIMessage',
    variables: {
      input: {
        type: 'FYIMessageCreateInput',
        required: true,
        value: {
          ...inputVariables,
          dateFrom: cloneVariable?.dateFrom
            ? dayjs(cloneVariable?.dateFrom).format(COMMON_DATE_FORMAT)
            : null,
          dateTo: cloneVariable?.dateTo
            ? dayjs(cloneVariable?.dateTo).format(COMMON_DATE_FORMAT)
            : null,
          locationId: metaData?.locationId
        }
      }
    },
    fields: metaData?.fields
  });

  const response = await graphqlClient.request(query, variables);

  return {
    data: response
  };
};

locationsFYIDataProvider.deleteOne = async ({ id, metaData }) => {
  const { query, variables } = gql.mutation({
    operation: 'deactiveFYIMessage',
    variables: {
      id: { value: Number(id), required: true }
    },
    fields: metaData?.fields ?? ['id']
  });

  const response = await graphqlClient.request(query, variables);

  return {
    data: response
  };
};

locationsFYIDataProvider.update = async ({
  metaData,
  variables: inputVariables
}) => {
  const cloneVariable: any = inputVariables;

  const { query, variables } = gql.mutation({
    operation: 'updateFYIMessage',
    variables: {
      input: {
        type: 'FYIMessageUpdateInput',
        required: true,
        value: {
          ...inputVariables,
          dateFrom: cloneVariable?.dateFrom
            ? dayjs(cloneVariable?.dateFrom).format(COMMON_DATE_FORMAT)
            : null,
          dateTo: cloneVariable?.dateTo
            ? dayjs(cloneVariable?.dateTo).format(COMMON_DATE_FORMAT)
            : null,
          id: metaData?.id
        }
      }
    },
    fields: metaData?.fields
  });

  const response = await graphqlClient.request(query, variables);

  return {
    data: response
  };
};
