import { Option } from '@pankod/refine-core';

import { Country } from '../models/country';

export function buildCountrySelectOptions(countries: Country[]): Option[] {
  return countries.map(country => ({
    label: country.name,

    // FIXME: Should use country.code when the country code is available in products table
    value: country.name
  }));
}
