import dataProvider from '@pankod/refine-hasura';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as gqlQueryBuilder from 'gql-query-builder';
import { GraphqlService } from 'services/graphql';
import { handleImageUpload } from 'utils/handleImageUpload';
import type { Products } from 'utils/hasura';
import { UPDATE_PRODUCT_BY_ID, UPDATE_TOUR_BY_ID } from './graphql/tour';

const { client: graphqlClient } = GraphqlService;

export const toursDataProvider = dataProvider(graphqlClient);

toursDataProvider.getOne = async ({ id, metaData }) => {
  const operation = 'products_by_pk';

  const { query, variables } = gqlQueryBuilder.query({
    operation,
    variables: {
      id: { value: id, type: 'Int', required: true },
      ...metaData?.variables
    },
    fields: metaData?.fields
  });

  const response = await graphqlClient.request(query, variables);
  const product = response[operation];
  const { tour } = product;

  return {
    data: {
      ...product,
      images: product.images?.map((url: string) => ({
        thumbUrl: url
      })),
      tour: {
        ...(tour || {}),
        start_time: tour?.start_time?.split(','),
        end_time: tour?.end_time?.split(',')
      }
    }
  };
};

toursDataProvider.update = async ({ variables, id }) => {
  const { tour: tourParamsWithId, ...productParams } =
    (variables as unknown as Products) || {};

  const {
    id: tourId,
    start_time: startTime,
    end_time: endTime,
    ...tourParams
  } = tourParamsWithId || {};

  const imageUrls = await handleImageUpload(
    productParams.images || [],
    'PRODUCT'
  );

  productParams.images = `{${imageUrls.join(',')}}`;
  const { update_products_by_pk: product } = await graphqlClient.request(
    UPDATE_PRODUCT_BY_ID,
    {
      pk_columns: { id },
      set: productParams
    }
  );

  const { update_tours_by_pk: tours } = await graphqlClient.request(
    UPDATE_TOUR_BY_ID,
    {
      pk_columns: { id },
      set: {
        ...tourParams,
        start_time: Array.isArray(startTime) ? startTime?.join() : startTime,
        end_time: Array.isArray(endTime) ? endTime?.join() : endTime
      }
    }
  );

  product.tours = tours;

  return {
    data: product
  };
};
