import { notificationProvider } from '@pankod/refine-antd';
import { Refine } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';

import 'styles/antd.less';
import 'styles/global.css';

import {
  Footer,
  Header,
  Layout,
  OffLayoutArea,
  Sider,
  Title
} from 'components/layout';
import { resources } from 'pages';
import { HealthPage } from 'pages/health';
import { LoginPage } from 'pages/login';
import { ReadyPage } from 'pages/ready';

import { defaultDataProvider } from 'data-provider/defaultDataProvider';
import { transportationDataProvider } from 'data-provider/transportationDataProvider';
import { liveProvider } from 'live-provider';
import { authProvider } from './data-provider/authProvider';
import { hotelDataProvider } from './data-provider/hotelDataProvider';
import { locationsDataProvider } from './data-provider/locationsDataProvider';
import { locationsFYIDataProvider } from './data-provider/locationsFYIDataProvider';
import { organizationsDataProvider } from './data-provider/organizationsDataProvider';
import { toursDataProvider } from './data-provider/toursDataProvider';

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language
  };

  return (
    <Refine
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <HealthPage />,
            path: '/health'
          },
          {
            element: <ReadyPage />,
            path: '/ready'
          }
        ]
      }}
      notificationProvider={notificationProvider}
      dataProvider={{
        default: defaultDataProvider,
        organizationsDataProvider,
        locationsDataProvider,
        locationsFYIDataProvider,
        toursDataProvider,
        transportationDataProvider,
        hotelDataProvider
      }}
      liveProvider={liveProvider}
      authProvider={authProvider}
      LoginPage={LoginPage}
      Title={Title}
      Header={Header}
      Sider={Sider}
      resources={resources}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      i18nProvider={i18nProvider}
    />
  );
}

export default App;
