import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';
import { Organizations, ProductMapping } from 'utils/hasura';

import { OrganizationItem } from 'models/organization';
import { GraphqlService } from 'services/graphql';

const { client: graphqlClient } = GraphqlService;

const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations(order_by: { name: asc }) {
      id
      name
    }
  }
`;

const GET_PRODUCT_MAPPINGS = gql`
  query GetProductMapping {
    product_mapping(distinct_on: [organization_id]) {
      organization_id
    }
  }
`;

export function useOrganizations(): OrganizationItem[] {
  const [organizationItems, setOrganizationItems] = useState<
    OrganizationItem[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        const { organizations = [] } =
          (await graphqlClient.request<{
            organizations: Organizations[];
          }>(GET_ORGANIZATIONS)) || [];

        const { product_mapping: productMappings = [] } =
          (await graphqlClient.request<{
            product_mapping: ProductMapping[];
          }>(GET_PRODUCT_MAPPINGS)) || [];

        const items = organizations.map(organization => {
          const organizationId = organization.id;

          return {
            id: organizationId,
            name: organization.name,
            isMapping: !!productMappings.find(
              productMapping =>
                productMapping.organization_id === organizationId
            )
          };
        });

        setOrganizationItems(items);
      } catch (error) {
        Promise.reject((error as Error).message);
      }
    })();
  }, []);

  return organizationItems;
}
