import {
  GraphQLClient as Client,
  Variables,
  RequestDocument
} from 'graphql-request';

import { generateSort } from 'utils/generateSort';
import { generateFilter } from 'utils/generateFilter';

const clientRequest = Client.prototype.request;

/**
 * Make GraphQL Raw Request
 * @param this
 * @param document
 * @param queryVariables
 * @param requestHeaders
 * @returns
 */
async function request(
  this: Client,
  document: RequestDocument,
  queryVariables?: Variables,
  requestHeaders?: RequestInit['headers']
): Promise<any> {
  let variables = queryVariables;
  let orderBy = variables?.order_by;
  let filter = variables?.where;

  if (orderBy) {
    orderBy = generateSort(orderBy);

    variables = {
      ...variables,
      order_by: orderBy
    };
  }

  if (filter) {
    filter = generateFilter(filter);

    variables = {
      ...variables,
      where: filter
    };
  }

  return clientRequest.bind(this)(document, variables, requestHeaders);
}

Client.prototype.request = request as any;

export { Client as GraphQLClient };
