import { Option } from '@pankod/refine-core';

export function buildLocationCategorySelectOptions(
  categories: string[]
): Option[] {
  return categories.map(category => ({
    label: category,
    value: category
  }));
}
