import { gql } from 'graphql-request';

export const SYNC_CONVERSION_RATES = gql`
  mutation SyncConversionRates(
    $provider_servers: [Int]
    $organizations: [Int]
  ) {
    syncConversionRates(
      provider_servers: $provider_servers
      organizations: $organizations
    ) {
      success
    }
  }
`;

export const SYNC_EXCHANGE_RATES = gql`
  mutation SyncExchangeRates {
    syncExchangeRates {
      success
    }
  }
`;

export const SYNC_LOCATIONS = gql`
  mutation SyncLocations($provider_servers: [Int], $organizations: [Int]) {
    syncLocations(
      provider_servers: $provider_servers
      organizations: $organizations
    ) {
      success
    }
  }
`;

export const SYNC_ACCOMMODATIONS = gql`
  mutation SyncAccommodations($provider_servers: [Int], $organizations: [Int]) {
    syncAccommodations(
      provider_servers: $provider_servers
      organizations: $organizations
    ) {
      success
    }
  }
`;

export const SYNC_TOUR = gql`
  mutation SyncTours($provider_servers: [Int], $organizations: [Int]) {
    syncTours(
      provider_servers: $provider_servers
      organizations: $organizations
    ) {
      success
    }
  }
`;

export const SYNC_TRANSPORTATIONS = gql`
  mutation SyncTransportations(
    $provider_servers: [Int]
    $organizations: [Int]
  ) {
    syncTransportations(
      provider_servers: $provider_servers
      organizations: $organizations
    ) {
      success
    }
  }
`;

export const SYNC_SUPPLIER = gql`
  mutation SyncSupplier($provider_servers: [Int], $organizations: [Int]) {
    syncSuppliers(
      provider_servers: $provider_servers
      organizations: $organizations
    ) {
      success
    }
  }
`;

export const SYNC_CRUISE_ACCOMMODATION = gql`
  mutation SyncCruiseAccommodation(
    $provider_servers: [Int]
    $organizations: [Int]
  ) {
    syncCruiseAccommodations(
      provider_servers: $provider_servers
      organizations: $organizations
    ) {
      success
      job_arn
    }
  }
`;
