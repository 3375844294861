/**
 * Transform orderBy format
 * From { parent, child: 'asc' } to { parent: { child: 'asc' } }
 */
export function generateSort(
  orderBy: Record<string, any>
): Record<string, any> {
  let sort = {};

  if (orderBy) {
    sort = Object.keys(orderBy).reduce((previous: any, current) => {
      const fields = current.split(',');
      const lastIndex = fields.length - 1;
      const value = { ...previous };

      let lastFieldValue = value;

      fields.forEach((field, index) => {
        if (index === lastIndex) {
          lastFieldValue[field] = orderBy[current];

          return;
        }

        Object.assign(lastFieldValue, { [field]: lastFieldValue[field] || {} });
        lastFieldValue = lastFieldValue[field];
      });

      return value;
    }, {});
  }

  return sort;
}
