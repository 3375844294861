import { gql } from 'graphql-request';

export const VERIFY_API_KEY = gql`
  mutation VerifyApiKey(
    $type: VerifyApiKeyType!
    $serverId: String
    $serverPassword: String
    $apiKey: String
    $apiPassword: String
  ) {
    verifyApiKey(
      type_test: $type
      agent_id: $serverId
      password: $serverPassword
      api_key: $apiKey
      api_secret: $apiPassword
    ) {
      isAuthorized
    }
  }
`;
