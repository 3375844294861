// eslint-disable-next-line import/no-extraneous-dependencies
import * as gql from 'gql-query-builder';

import { GraphqlService } from 'services/graphql';
import { handleImageUpload } from 'utils/handleImageUpload';
import { numberToTime } from 'utils/parseDateTime';
import { commonProvider } from './commonProvider';

const { client: graphqlClient } = GraphqlService;

const dataProvider = commonProvider(graphqlClient);

const { update } = dataProvider;

dataProvider.getOne = async ({ resource, id, metaData }) => {
  const hotelOperation = `${metaData?.operation ?? resource}_by_pk`;
  const { query, variables } = gql.query({
    operation: hotelOperation,
    variables: {
      id: { value: id, type: 'Int', required: true },
      ...metaData?.variables
    },
    fields: metaData?.fields
  });

  const response = await graphqlClient.request(query, variables);

  // Format data
  const formatedHotel = response[hotelOperation];

  formatedHotel.checkin_time = numberToTime(formatedHotel.checkin_time);
  formatedHotel.checkout_time = numberToTime(formatedHotel.checkout_time);
  formatedHotel.images = (formatedHotel.images || []).map((url: string) => ({
    thumbUrl: url
  }));

  return {
    data: {
      ...formatedHotel,
      location: `${formatedHotel?.location?.latitude}, ${formatedHotel?.location?.longitude}`
    }
  };
};

dataProvider.update = async ({ resource, metaData, variables, id }) => {
  const cloneVaraiables: any = variables;

  const imageUrls = await handleImageUpload(
    cloneVaraiables.images || [],
    'PRODUCT'
  );
  // set location variable
  if (cloneVaraiables.location) {
    const locationLatLng = {
      lat: cloneVaraiables.location.split(',')[0] || 0,
      lng: cloneVaraiables.location.split(',')[0] || 0
    };
    cloneVaraiables.location = locationLatLng;
  }

  cloneVaraiables.images = `{${imageUrls.join(',')}}`;

  cloneVaraiables.checkin_time = cloneVaraiables.checkin_time
    ? +cloneVaraiables.checkin_time.format('HHmm')
    : null;
  cloneVaraiables.checkout_time = cloneVaraiables.checkout_time
    ? +cloneVaraiables.checkout_time.format('HHmm')
    : null;

  return update({ resource, metaData, variables: cloneVaraiables, id });
};

export const hotelDataProvider = dataProvider;
