/* eslint-disable import/no-extraneous-dependencies */
import { ColumnType } from 'antd/lib/table';

export function buildTableColumn<T = any>(
  title: string | JSX.Element,
  dataIndex?: string | string[],
  isSorted = true,
  render?: (value: any, record: T, index: number) => React.ReactNode
): ColumnType<T> {
  const column: ColumnType<T> = {
    title,
    dataIndex,
    showSorterTooltip: false,
    render: render || undefined
  };

  if (isSorted) {
    column.sorter = {
      multiple: 1
    };
  }

  return column;
}
