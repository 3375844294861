import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';
import { Products } from 'utils/hasura';

import { GraphqlService } from 'services/graphql';

import { ProductType } from '../models/product';

const { client: graphqlClient } = GraphqlService;

const GET_PRODUCT_TYPES = gql`
  query GetProductTypes {
    products(
      distinct_on: [product_class]
      where: { product_class: { _is_null: false } }
    ) {
      product_class
      class_description
    }
  }
`;

export function useProductTypes(): ProductType[] {
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);

  useEffect(() => {
    graphqlClient
      .request<{
        products: Products[];
      }>(GET_PRODUCT_TYPES)
      .then(({ products }) => {
        setProductTypes(
          products
            .map(item => ({
              class: item.product_class || '',
              description: item.class_description || ''
            }))
            .filter(item => !!item.class)
        );
      })
      .catch(err => Promise.reject(err.message));
  }, []);

  return productTypes;
}
