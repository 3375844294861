import {
  Col,
  Edit,
  Form,
  Input,
  ListButton,
  Row,
  Select,
  Tabs,
  TimePicker,
  useForm
} from '@pankod/refine-antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Suppliers } from 'utils/hasura';

import { ImageUpload } from 'components/ImageUpload';
import { MapBox } from 'components/MapBox';
import { OrganizationCheckboxList } from 'components/OrganizationCheckboxList';
import { SupplierAccommodation } from 'components/SupplierAccommodation';
import { TextEditor } from 'components/TextEditor';
import { checkLocationIsValid } from 'utils';
import { buildAmenityOptions } from 'utils/buildAmenityOptions';
import styles from './index.module.scss';

const { TabPane } = Tabs;
interface SuppliertEditProps {
  isHotelSupplier?: boolean;
}

export const SupplierEdit: React.FC<SuppliertEditProps> = ({
  isHotelSupplier = true
}: SuppliertEditProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [locationHotel, setLocationHotel] = useState('');

  const { formProps, saveButtonProps, queryResult, form } = useForm<Suppliers>({
    dataProviderName: 'hotelDataProvider',
    metaData: {
      operation: 'suppliers',
      fields: [
        'id',
        'name',
        'language_name',
        'address',
        'email',
        'fax',
        'post_code',
        'phone',
        'web',
        'checkin_time',
        'checkout_time',
        'images',
        'location',
        'child_policy',
        'cancelation_policy',
        'location',
        'description',
        'promotion_information'
      ]
    }
  });

  useEffect(() => {
    if (queryResult?.isLoading) return;
    const { location } = queryResult?.data?.data as any;
    setLocationHotel(location);
  }, [queryResult?.isLoading]);
  const setLocationChangValue = (type: string, value: string) => {
    setLocationHotel(value);
    form.setFieldsValue({
      location: value
    });
  };
  return (
    <Edit
      saveButtonProps={{ ...saveButtonProps, disabled: true }}
      pageHeaderProps={{ extra: <ListButton /> }}
    >
      <Form {...formProps} layout="vertical">
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={t('pages.productEdit.tabs.generalInformation')}
            key="1"
            forceRender
          >
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.name.label')
                      : t('pages.cruiseEdit.fields.name.label')
                  }
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: isHotelSupplier
                        ? t('pages.hotelEdit.fields.name.errors.required')
                        : t('pages.cruiseEdit.fields.name.errors.required')
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.availableLanguages.label')
                      : t('pages.cruiseEdit.fields.availableLanguages.label')
                  }
                  // TODO: Will need add field to DB
                  name=""
                >
                  <Select
                    placeholder={
                      isHotelSupplier
                        ? t(
                            'pages.hotelEdit.fields.availableLanguages.placeholder'
                          )
                        : t(
                            'pages.cruiseEdit.fields.availableLanguages.placeholder'
                          )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.address.label')
                      : t('pages.cruiseEdit.fields.address.label')
                  }
                  name="address"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.email.label')
                      : t('pages.cruiseEdit.fields.email.label')
                  }
                  name="email"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.fax.label')
                      : t('pages.cruiseEdit.fields.fax.label')
                  }
                  name="fax"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.postCode.label')
                      : t('pages.cruiseEdit.fields.postCode.label')
                  }
                  name="post_code"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.paymentAccepted.label')
                      : t('pages.cruiseEdit.fields.paymentAccepted.label')
                  }
                  // TODO: Will need add field to DB
                  name=""
                >
                  <Select
                    placeholder={
                      isHotelSupplier
                        ? t(
                            'pages.hotelEdit.fields.paymentAccepted.placeholder'
                          )
                        : t(
                            'pages.cruiseEdit.fields.paymentAccepted.placeholder'
                          )
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.currenciesAccepted.label')
                      : t('pages.cruiseEdit.fields.currenciesAccepted.label')
                  }
                  // TODO: Will need add field to DB
                  name=""
                >
                  <Select
                    placeholder={
                      isHotelSupplier
                        ? t(
                            'pages.hotelEdit.fields.currenciesAccepted.placeholder'
                          )
                        : t(
                            'pages.cruiseEdit.fields.currenciesAccepted.placeholder'
                          )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item>
                  <Form.Item
                    label={
                      isHotelSupplier
                        ? t('pages.hotelEdit.fields.phone.label')
                        : t('pages.cruiseEdit.fields.phone.label')
                    }
                    name="phone"
                    className={styles.phoneCol}
                  >
                    <Input />
                  </Form.Item>
                  <small>ex. +99 99 999 999</small>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.website.label')
                      : t('pages.cruiseEdit.fields.website.label')
                  }
                  name="web"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.checkIn.label')
                      : t('pages.cruiseEdit.fields.checkIn.label')
                  }
                  name="checkin_time"
                >
                  <TimePicker
                    className={styles.timePicker}
                    use12Hours
                    format="h:mm A"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    isHotelSupplier
                      ? t('pages.hotelEdit.fields.checkOut.label')
                      : t('pages.cruiseEdit.fields.checkOut.label')
                  }
                  name="checkout_time"
                >
                  <TimePicker
                    className={styles.timePicker}
                    use12Hours
                    format="h:mm A"
                  />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tab={t('pages.productEdit.tabs.description')}
            key="2"
            forceRender
          >
            <Row gutter={[40, 16]}>
              <Col span={24}>
                <TextEditor
                  name="description"
                  label={t('pages.hotelEdit.fields.description.label')}
                />
              </Col>
              <Col span={24}>
                <TextEditor
                  name="promotion_information"
                  label={t('pages.hotelEdit.fields.promotionInformation.label')}
                />
              </Col>
              <Col span={24}>
                <TextEditor
                  name="cancelation_policy"
                  label={t('pages.hotelEdit.fields.cancellationPolicy.label')}
                />
              </Col>
              <Col span={24}>
                <TextEditor
                  name="child_policy"
                  label={t('pages.hotelEdit.fields.childPolicy.label')}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane
            tab={t('pages.productEdit.tabs.amenities')}
            key="3"
            forceRender
          >
            <Row gutter={[40, 16]}>
              <Col span={24}>
                <Form.Item
                  label={t('pages.hotelEdit.fields.amenities.label')}
                  // TODO: Will need add field to DB
                  name=""
                >
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    filterOption={(input, option) => {
                      const value = input?.toLocaleLowerCase();

                      return !!option?.label
                        ?.toLocaleLowerCase()
                        .includes(value);
                    }}
                    options={buildAmenityOptions()}
                  />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab={t('pages.productEdit.tabs.rooms')} key="4">
            <SupplierAccommodation
              supplierId={parseInt(id!, 10)}
              isHotelSupplier={isHotelSupplier}
            />
          </TabPane>

          <TabPane tab={t('pages.productEdit.tabs.images')} key="5" forceRender>
            <Form.Item name="images">
              <ImageUpload />
            </Form.Item>
          </TabPane>
          <TabPane tab={t('pages.productEdit.tabs.map')} key="6">
            {locationHotel.includes('undefined') ? (
              <div className="flex justify-center h-[300px] items-center">
                <h4 className="text-center">
                  {t('pages.hotelEdit.no-map-display')}
                </h4>
              </div>
            ) : (
              <>
                <Row gutter={[40, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label={t('pages.productEdit.location')}
                      name="location"
                    >
                      <Input
                        value={locationHotel}
                        onChange={e => setLocationHotel(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <MapBox
                  position={[
                    {
                      key: 'A ',
                      location: checkLocationIsValid(locationHotel)
                    }
                  ]}
                  widthMap={'auto'}
                  heightMap={400}
                  edit
                  setLocationInputs={setLocationChangValue}
                />
              </>
            )}
          </TabPane>
          <TabPane
            tab={t('pages.productEdit.tabs.agencies')}
            key="7"
            forceRender
          >
            <OrganizationCheckboxList />
          </TabPane>
        </Tabs>
      </Form>
    </Edit>
  );
};
