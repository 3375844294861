import { Form } from '@pankod/refine-antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from 'rc-field-form/lib/interface';

export interface TextEditorProps {
  name: string | string[];
  label?: string;
  rules?: Rule[];
}

export const TextEditor: React.FC<TextEditorProps> = (
  props: TextEditorProps
) => (
  <Form.Item {...props} initialValue="">
    <ReactQuill />
  </Form.Item>
);

TextEditor.defaultProps = {
  label: undefined,
  rules: undefined
};
