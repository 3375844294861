import * as dayjs from 'dayjs';

export const numberToTime = (num: number) => {
  if (!num) {
    return null;
  }
  // Split hours and minutes
  const time = [Math.floor(num / 100), num % 100];
  return dayjs
    .default(new Date())
    .set('hours', time[0])
    .set('minutes', time[1]);
};

export const formatDuration = (durations: number): string => {
  let ms = durations;
  const dayMilliseconds = 24 * 3600 * 1000;
  const hourMilliseconds = 3600 * 1000;
  const minuteMilliseconds = 60 * 1000;

  const days = Math.floor(ms / dayMilliseconds);

  ms %= dayMilliseconds;
  const hours = Math.floor(ms / hourMilliseconds);

  ms %= hourMilliseconds;
  const minutes = Math.floor(ms / minuteMilliseconds);

  ms %= minuteMilliseconds;
  const seconds = Math.floor(ms / 1000);

  const milliseconds = ms % 1000;

  let result = '';
  if (days > 0) {
    result += `${days}d`;
  }

  if (hours > 0) {
    result += `${hours}h`;
  }

  if (minutes > 0) {
    result += `${minutes}m`;
  }

  if (seconds > 0) {
    result += `${seconds}s`;
  }

  if (milliseconds > 0 || result === '') {
    result += `${milliseconds}ms`;
  }

  return result;
};
