import { Form, FormInstance, Input, Select } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';

import { useTourCodes } from 'hooks/useTourCodes';

export interface TourCodesSelectProps {
  form: FormInstance;
}

export const TourCodesSelect: React.FC<TourCodesSelectProps> = ({
  form
}: TourCodesSelectProps) => {
  const { t } = useTranslation();
  const tourCodes = useTourCodes();

  const handleTourCodesChange = (value: string) => {
    form.setFieldsValue({
      code6_description: tourCodes.find(item => item.code6 === value)
        ?.code6_description
    });
  };

  return (
    <>
      <Form.Item label={t('pages.organizationEdit.tourCode')} name="code6">
        <Select
          options={tourCodes.map(item => ({
            label: item.code6_description,
            value: item.code6
          }))}
          onChange={handleTourCodesChange}
        />
      </Form.Item>
      <Form.Item name="code6_description" hidden>
        <Input />
      </Form.Item>
    </>
  );
};
