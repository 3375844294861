/* eslint-disable no-param-reassign */
import {
  IParentItemType,
  IParentTreeProps,
  IParentTreeType
} from 'models/location';
import styles from './index.module.scss';

function ListItem({ item }: IParentItemType) {
  let children = null;
  if (item.child) {
    children = (
      <ul className={styles.parentList}>
        <ListItem item={item.child} key={item.child.sygicId} />
      </ul>
    );
  }

  return (
    <li>
      <div>
        <strong>{item.name}</strong>
        <span> - </span>
        <small>{item.sygicId}</small>
      </div>
      <i>{item.level}</i>
      {children}
    </li>
  );
}

export const ParentTree: React.FC<IParentTreeType> = ({
  value
}: IParentTreeType) => {
  if (!value?.length) {
    return <div />;
  }

  // Format to object tree to build nested list
  const parentList = [...value];
  let objItem: IParentTreeProps = parentList[0] || {};
  const objTree = objItem;
  parentList.shift();
  parentList.forEach(item => {
    objItem.child = item;
    objItem = objItem.child;
  });

  return (
    <ul className={styles.parentList}>
      <ListItem item={objTree} key={objTree.sygicId} />
    </ul>
  );
};

ParentTree.defaultProps = {
  value: []
};
