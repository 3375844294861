import { Icons } from '@pankod/refine-antd';

import { TourList } from './TourList';
import { TourEdit } from './TourEdit';

export const tours = {
  name: 'tours',
  icon: <Icons.DatabaseOutlined />,
  list: TourList,
  edit: TourEdit
};
