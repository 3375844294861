export const COMMON_DATE_FORMAT = 'YYYY-MM-DD';
export const COMMON_TIME_FORMAT = 'HH:mm';

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  TRAVEL_CONSULTANT_MANAGER = 'TRAVEL_CONSULTANT_MANAGER',
  TRAVEL_CONSULTANT = 'TRAVEL_CONSULTANT',
  TRAVEL_AGENCY_MANAGER = 'TRAVEL_AGENCY_MANAGER',
  TRAVEL_AGENCY_SPECIALIST = 'TRAVEL_AGENCY_SPECIALIST',
  TRAVEL_AGENT = 'TRAVEL_AGENT',
  SUPPLIER_MANAGER = 'SUPPLIER_MANAGER',
  SUPPLIER_EDITOR = 'SUPPLIER_EDITOR'
}

export enum SyncStatus {
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export enum ServiceType {
  HOTEL = 'HOTEL',
  ACCOMMODATION = 'ACCOMMODATION',
  CRUISE = 'CRUISE',
  CRUISE_ACCOMMODATION = 'CRUISE_ACCOMMODATION',
  TOUR = 'TOUR',
  TRANSPORTATION = 'TRANSPORTATION',
  CUSTOM = 'CUSTOM'
}
