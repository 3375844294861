import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './i18n';
import 'leaflet/dist/leaflet.css';
import Config from './configs/config.json';

const IGNOR_MESSAGES = [
  'You do not have permission to access the Admin tool.',
  'Authentication hook unauthorized this request'
];

if (Config.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      Config.SENTRY_DNS ||
      'https://aeac382b54d04d49adde0ea1f5ac6af1@o4503988352516096.ingest.sentry.io/123456789',
    release: process.env.REACT_APP_RELEASE_VERSION,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
    sampleRate: 1,
    beforeSend: event => {
      if (event.message && IGNOR_MESSAGES.includes(event.message)) {
        return null;
      }
      return event;
    }
  });
}

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
