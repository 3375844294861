import Config from './config.json';

const {
  HASURA_GRAPHQL_API_SCHEMA,
  HASURA_GRAPHQL_WS_SCHEMA,
  // TODO: Will apply API gateway later
  HASURA_GRAPHQL_HOST
} = Config;

export const GRAPHQL_MASTER_URL = `${HASURA_GRAPHQL_API_SCHEMA}://${HASURA_GRAPHQL_HOST}/v1/graphql`;
export const GRAPHQL_REALTIME_URL = `${HASURA_GRAPHQL_WS_SCHEMA}://${HASURA_GRAPHQL_HOST}/v1/graphql`;
