import { Icons } from '@pankod/refine-antd';

import { LocationList } from './LocationList';
import { LocationEdit } from './LocationEdit';

export const locations = {
  name: 'locations',
  icon: <Icons.SendOutlined />,
  list: LocationList,
  edit: LocationEdit
};
