import { TFunction } from 'react-i18next';

export function buildPersonTitleOptions(t: TFunction) {
  return [
    {
      value: 'Mr',
      label: t('pages.userForm.personTitle.Mr')
    },
    {
      value: 'Mrs',
      label: t('pages.userForm.personTitle.Mrs')
    },
    {
      value: 'Ms',
      label: t('pages.userForm.personTitle.Ms')
    },
    {
      value: 'Master',
      label: t('pages.userForm.personTitle.Master')
    },
    {
      value: 'Miss',
      label: t('pages.userForm.personTitle.Miss')
    }
  ];
}
