import { Form, FormInstance, Input, Select } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';

import { useProductTypes } from 'hooks/useProductTypes';

export interface ProductTypeSelectProps {
  form: FormInstance;
}

export const ProductTypeSelect: React.FC<ProductTypeSelectProps> = ({
  form
}: ProductTypeSelectProps) => {
  const { t } = useTranslation();
  const productTypes = useProductTypes();

  const handleProductTypeChange = (value: string) => {
    form.setFieldsValue({
      class_description: productTypes.find(item => item.class === value)
        ?.description
    });
  };

  return (
    <>
      <Form.Item
        label={t('pages.transportationEdit.fields.productClass.label')}
        name="product_class"
      >
        <Select
          options={productTypes.map(item => ({
            label: item.description,
            value: item.class
          }))}
          onChange={handleProductTypeChange}
        />
      </Form.Item>
      <Form.Item name="class_description" hidden>
        <Input />
      </Form.Item>
    </>
  );
};
