import {
  Button,
  Form,
  FormProps,
  Table,
  TableProps,
  List,
  PageHeaderProps
} from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';

interface ListPageLayoutProps<T = any> {
  tableProps: TableProps<T>;
  searchFormProps: FormProps;
  rowKey?: string;
  pageHeaderProps?: PageHeaderProps;
}

export const ListPageLayout: React.FC<ListPageLayoutProps> = ({
  tableProps,
  searchFormProps,
  rowKey,
  pageHeaderProps
}: ListPageLayoutProps) => {
  const { t } = useTranslation();

  return (
    <List pageHeaderProps={{ ...(pageHeaderProps || {}) }}>
      <Form {...searchFormProps}>
        <Table {...tableProps} rowKey={rowKey} />

        <Button htmlType="submit" type="primary" hidden>
          {t('pages.listPageLayout.filterButton')}
        </Button>
      </Form>
    </List>
  );
};

ListPageLayout.defaultProps = {
  rowKey: 'id'
};
