import { gql } from 'graphql-request';

export const UPDATE_PRODUCT_BY_ID = gql`
  mutation UpdateProduct(
    $pk_columns: products_pk_columns_input!
    $set: products_set_input
  ) {
    update_products_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const UPDATE_TOUR_BY_ID = gql`
  mutation UpdateTour(
    $pk_columns: tours_pk_columns_input!
    $set: tours_set_input
  ) {
    update_tours_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;
