import { Space, DeleteButton } from '@pankod/refine-antd';

export interface DeleteActionProps {
  id: number;
}

export const DeleteAction: React.FC<DeleteActionProps> = ({
  id
}: DeleteActionProps) => (
  <Space>
    <DeleteButton size="small" recordItemId={id} hideText />
  </Space>
);
