import { Icons } from '@pankod/refine-antd';
import { OrganizationList } from './OrganizationList';
import { OrganizationCreate } from './OrganizationCreate';
import { OrganizationEdit } from './OrganizationEdit';

export const organizations = {
  name: 'organizations',
  list: OrganizationList,
  icon: <Icons.ShopOutlined />,
  create: OrganizationCreate,
  edit: OrganizationEdit
};
