import { GRAPHQL_MASTER_URL } from 'configs';
import { padZero } from 'utils/strings';
import { GraphQLClient } from 'services/graphql/client';

class GraphqlService {
  private readonly graphqlClient: GraphQLClient;

  constructor() {
    this.graphqlClient = this.buildClient();
  }

  get client(): GraphQLClient {
    return this.graphqlClient;
  }

  /**
   * Convert date time
   * @param date
   * @returns
   */
  convertToDate(date: Date): string {
    return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
      date.getDate()
    )}`;
  }

  convertToDateTime(date: Date): string {
    const d = this.convertToDate(date);
    const t = `${padZero(date.getHours())}:${padZero(
      date.getMinutes()
    )}:${padZero(date.getSeconds())}`;
    return `${d} ${t}`;
  }

  buildCacheId(type: string, id: string): string {
    return `${type}:${id}`;
  }

  private buildClient(): GraphQLClient {
    return new GraphQLClient(GRAPHQL_MASTER_URL);
  }
}

const singleton = new GraphqlService();

export { singleton as GraphqlService };
