import {
  Col,
  Edit,
  Form,
  Input,
  ListButton,
  Row,
  Select,
  Tabs,
  useForm
} from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { Transportations } from 'utils/hasura';

import { ImageUpload } from 'components/ImageUpload';
import { OrganizationCheckboxList } from 'components/OrganizationCheckboxList';
import { ProductTypeSelect } from 'components/ProductTypeSelect';
import { TextEditor } from 'components/TextEditor';
import { useCountries } from 'hooks/useCountries';
import { useVehicleTypes } from 'hooks/useVehicleTypes';
import { buildCountrySelectOptions } from 'utils/buildCountrySelectOptions';
import { buildGuideLanguageOptions } from 'utils/buildGuideLanguageOptions';

const { TabPane } = Tabs;

export const TransportationEdit: React.FC = () => {
  const { t } = useTranslation();
  const countries = useCountries();
  const vehicleTypes = useVehicleTypes();

  const { form, formProps, saveButtonProps } = useForm<Transportations>({
    resource: 'transportations',
    metaData: {
      operation: 'products',
      fields: [
        'name',
        'locality',
        'address5',
        'product_class',
        'class_description',
        {
          transportation: [
            'id',
            'vehicle_type',
            'xhr',
            'guide',
            'start_location',
            'end_location'
          ]
        },
        'comment',
        'description',
        'images'
      ]
    },
    dataProviderName: 'transportationDataProvider'
  });

  return (
    <Edit
      saveButtonProps={{ ...saveButtonProps, disabled: true }}
      pageHeaderProps={{ extra: <ListButton /> }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item name={['transportation', 'id']} hidden>
          <Input />
        </Form.Item>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={t('pages.productEdit.tabs.generalInformation')}
            key="1"
            forceRender
          >
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  label={t('pages.transportationEdit.fields.name.label')}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t(
                        'pages.transportationEdit.fields.name.errors.required'
                      )
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('pages.transportationEdit.fields.xhr.label')}
                  name={['transportation', 'xhr']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[40, 16]}>
              <Col span={24}>
                <Form.Item
                  label={t('pages.transportationEdit.fields.locality.label')}
                  name="locality"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  label={t('pages.transportationEdit.fields.from.label')}
                  name={['transportation', 'start_location']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('pages.transportationEdit.fields.to.label')}
                  name={['transportation', 'end_location']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[40, 16]}>
              <Col span={8}>
                <Form.Item
                  label={t('pages.transportationEdit.fields.address5.label')}
                  name="address5"
                >
                  <Select options={buildCountrySelectOptions(countries)} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <ProductTypeSelect form={form} />
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('pages.transportationEdit.fields.vehicleType.label')}
                  name={['transportation', 'vehicle_type']}
                >
                  <Select
                    options={vehicleTypes.map(item => ({
                      label: item,
                      value: item
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[40, 16]}>
              <Col span={24}>
                <Form.Item
                  label={t('pages.transportationEdit.fields.comment.label')}
                  name="comment"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>

          <TabPane
            tab={t('pages.productEdit.tabs.description')}
            key="2"
            forceRender
          >
            <TextEditor
              name="description"
              label={t('pages.transportationEdit.fields.description.label')}
            />

            <Form.Item
              label={t('pages.transportationEdit.fields.guide.label')}
              name={['transportation', 'guide']}
            >
              <Select
                mode="multiple"
                allowClear
                options={buildGuideLanguageOptions()}
              />
            </Form.Item>
          </TabPane>

          <TabPane tab={t('pages.productEdit.tabs.images')} key="3" forceRender>
            <Form.Item name="images">
              <ImageUpload />
            </Form.Item>
          </TabPane>

          <TabPane
            tab={t('pages.productEdit.tabs.agencies')}
            key="4"
            forceRender
          >
            <OrganizationCheckboxList />
          </TabPane>
        </Tabs>
      </Form>
    </Edit>
  );
};
