import {
  Pagination,
  CrudSorting,
  CrudFilters,
  MetaDataQuery
} from '@pankod/refine-core';
import * as gql from 'gql-query-builder';

type GenerateUseListSubscriptionParams = {
  resource: string;
  pagination?: Pagination;
  sorters?: CrudSorting;
  filters?: CrudFilters;
  meta: MetaDataQuery;
};

type GenerateUseListSubscriptionReturnValues = {
  variables: any;
  query: string;
  operation: string;
};

export const generateUseListSubscription = ({
  resource,
  meta
}: GenerateUseListSubscriptionParams): GenerateUseListSubscriptionReturnValues => {
  const operation = meta.operation ?? resource;

  const { query, variables } = gql.subscription([
    {
      operation,
      fields: meta.fields
    }
  ]);

  return { query, variables, operation };
};
