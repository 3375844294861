import { useEffect, useState } from 'react';
import { Locations } from 'utils/hasura';

import { GET_LOCATION_CATEGORIES } from 'graphql-queries';
import { GraphqlService } from 'services/graphql';

const { client: graphqlClient } = GraphqlService;

export function useLocationCategories(): string[] {
  const [locationCategories, setLocationCategories] = useState<string[]>([]);

  useEffect(() => {
    graphqlClient
      .request<{
        locations: Locations[];
      }>(GET_LOCATION_CATEGORIES)
      .then(({ locations }) => {
        let categories: string[] = [];
        locations.forEach(item => {
          categories = Array.from(new Set([...categories, ...item.categories]));
        });
        setLocationCategories(categories);
      })
      .catch(err => Promise.reject(err.message));
  }, []);

  return locationCategories;
}
