import { LiveProvider } from '@pankod/refine-core';
import { createClient } from 'graphql-ws';
import { StorageService } from 'services/storage';
import { AUTH_TOKEN_KEY } from 'services/auth';
import { GRAPHQL_REALTIME_URL } from 'configs';
import { generateUseListSubscription } from './generateUseListSubscription';

const gqlWebSocketClient = createClient({
  url: GRAPHQL_REALTIME_URL,
  connectionParams: async () => ({
    headers: {
      Authorization: `Bearer ${StorageService.get(AUTH_TOKEN_KEY)}`
    }
  })
});

const subscriptions: Record<'useList' | 'useOne' | 'useMany', any> = {
  useList: generateUseListSubscription,
  useOne: () => {},
  useMany: () => {}
};

export const liveProvider: LiveProvider = {
  subscribe: ({ callback, params }) => {
    const {
      resource,
      metaData,
      pagination,
      sorters,
      filters,
      subscriptionType,
      id,
      ids
    } = params ?? {};

    if (!metaData) {
      throw new Error(
        '[useSubscription]: `meta` is required in `params` for graphql subscriptions'
      );
    }

    if (!subscriptionType) {
      throw new Error(
        '[useSubscription]: `subscriptionType` is required in `params` for graphql subscriptions'
      );
    }

    if (!resource) {
      throw new Error(
        '[useSubscription]: `resource` is required in `params` for graphql subscriptions'
      );
    }

    const generateSubscription = subscriptions[subscriptionType];

    const { query, variables } = generateSubscription({
      ids,
      id,
      resource,
      filters,
      meta: metaData,
      pagination,
      sorters
    });

    const onNext = (payload: any) => {
      callback(payload.data[metaData.operation!]);
    };

    const unsubscribe = gqlWebSocketClient.subscribe(
      {
        query,
        variables
      },
      {
        next: onNext,
        error: () => null,
        complete: () => null
      }
    );

    return unsubscribe;
  },
  unsubscribe: unsubscribe => {
    unsubscribe();
  }
};
