import { Form, FormInstance, Select } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';

import { useDestinations } from 'hooks/useDestinations';

export interface IDestinationSelectProps {
  form: FormInstance;
}

export const DestinationSelect: React.FC<IDestinationSelectProps> = ({
  form
}: IDestinationSelectProps) => {
  const { t } = useTranslation();
  const destination = useDestinations();

  const handleDestinationChange = (value: string) => {
    form.setFieldsValue({
      address4: destination.find(item => item.destination === value)
        ?.destination
    });
  };

  return (
    <Form.Item label={t('pages.organizationEdit.destination')} name="address4">
      <Select
        options={destination.map(item => ({
          label: item.destination,
          value: item.destination
        }))}
        onChange={handleDestinationChange}
      />
    </Form.Item>
  );
};
