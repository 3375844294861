import { users } from './users';
import { locations } from './locations';
import { hotels } from './hotels';
import { cruises } from './cruises';
import { transportations, tours } from './products';
import { organizations } from './organizations';
import { sync } from './sync';

export const resources = [
  users,
  hotels,
  cruises,
  tours,
  transportations,
  locations,
  organizations,
  sync
];
