import { Switch, useTable } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { Locations } from 'utils/hasura';

import { CrudFilters, useUpdate } from '@pankod/refine-core';
import { EditAction } from 'components/EditAction';
import { TitleFilter } from 'components/TitleFilter';
import { useCountries } from 'hooks/useCountries';
import { buildFilterItem } from 'utils/buildFilterItem';

import { ListPageLayout } from 'components/layout/ListPageLayout';
import { buildTableColumn } from 'utils';
import { buildCountrySelectOptions } from 'utils/buildCountrySelectOptions';

const fields = [
  'id',
  'key',
  'name',
  'country',
  'country_name',
  'sygic_id',
  'deleted_at',
  'is_active'
];

export const LocationList: React.FC<any> = () => {
  const { mutate } = useUpdate<Locations>();
  const { t } = useTranslation();
  const countries = useCountries();

  const { tableProps, searchFormProps } = useTable<Locations>({
    initialSorter: [
      {
        field: 'key',
        order: 'asc'
      }
    ],
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const {
        key,
        name,
        country_name: countryName,
        sygic_id: sygicId
      } = params;

      filters.push(
        buildFilterItem('key', 'contains', key),
        buildFilterItem('name', 'contains', name),
        buildFilterItem('country_name', 'contains', countryName),
        buildFilterItem('sygic_id', 'contains', sygicId)
      );

      return filters;
    },
    metaData: {
      fields
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onToggleActive(checked: boolean) {
    // TODO: Need implement in Update feature
  }

  tableProps.columns = [
    buildTableColumn(
      <TitleFilter name="key" title={t('pages.locationList.code')} />,
      'key'
    ),
    buildTableColumn(
      <TitleFilter name="sygic_id" title={t('pages.locationList.sygicId')} />,
      'sygic_id'
    ),
    buildTableColumn(
      <TitleFilter name="name" title={t('pages.locationList.name')} />,
      'name'
    ),
    buildTableColumn(
      <TitleFilter
        name="country_name"
        title={t('pages.locationList.country')}
        filterType="dropdown"
        options={buildCountrySelectOptions(countries)}
      />,
      'country_name'
    ),
    buildTableColumn(
      t('pages.locationList.active'),
      'is_active',
      true,
      (value, location) => (
        <Switch
          checked={!!value}
          onChange={checked => {
            mutate({
              resource: 'locations',
              id: location.id,
              values: {
                is_active: checked
              }
            });
          }}
        />
      )
    ),
    buildTableColumn('', 'actions', false, (_text, record) => (
      <EditAction id={record.id} />
    ))
  ];

  return (
    <ListPageLayout tableProps={tableProps} searchFormProps={searchFormProps} />
  );
};
