import { Checkbox, Space } from '@pankod/refine-antd';

import { useOrganizations } from 'hooks/useOrganizations';

export const OrganizationCheckboxList: React.FC = () => {
  const organizations = useOrganizations();

  const checkboxList = organizations.map(item => (
    <Checkbox key={item.id} checked={item.isMapping} disabled>
      {item.name}
    </Checkbox>
  ));

  return <Space direction="vertical">{checkboxList}</Space>;
};
