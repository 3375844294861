import {
  Col,
  Edit,
  Form,
  Input,
  ListButton,
  Row,
  Select,
  Switch,
  useForm
} from '@pankod/refine-antd';
import { MapBox } from 'components/MapBox';
import { ParentTree } from 'components/ParentTree';
import { useCountries } from 'hooks/useCountries';
import { useLocationCategories } from 'hooks/useLocationCategories';
import { GeoJsonTypes } from 'models';
import { useTranslation } from 'react-i18next';
import { buildCountrySelectOptions } from 'utils/buildCountrySelectOptions';
import { buildLocationCategorySelectOptions } from 'utils/buildLocationCategorySelectOption';
import { Locations } from 'utils/hasura';
import { LocationFYI } from './LocationFYI';

import styles from './index.module.scss';

export const LocationEdit: React.FC = () => {
  const { t } = useTranslation();
  const countries = useCountries();
  const categories = useLocationCategories();

  const locationFields = [
    'id',
    'name',
    'key',
    'country',
    'country_name',
    'sygic_id',
    'name_local',
    'tag_keys',
    'level',
    'rating',
    'categories',
    'rating_local',
    'hotel_star_rating',
    'customer_rating',
    'quad_key',
    'perex',
    'place_parents',
    'is_active',
    'location',
    'geometry'
  ];

  const FormAction = Edit;
  const { TextArea } = Input;
  const { formProps, saveButtonProps, queryResult } = useForm<Locations>({
    dataProviderName: 'locationsDataProvider',
    metaData: {
      fields: locationFields
    },
    redirect: 'list'
  });

  return (
    <FormAction
      saveButtonProps={{ ...saveButtonProps, disabled: true }}
      pageHeaderProps={{ extra: <ListButton /> }}
    >
      <Form {...formProps} layout="vertical">
        <Row gutter={[40, 16]}>
          <Col span={24}>
            <h4 className={styles.titleHeader}>
              {t('pages.locationForm.headers.information')}
            </h4>
            <hr className={styles.splitLine} />
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.code.label')}
              name="key"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.name.label')}
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.country.label')}
              name="country_name"
            >
              <Select
                options={buildCountrySelectOptions(countries)}
                placeholder={t(
                  'pages.userForm.fields.organization.placeholder'
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={24}>
            <h4 className={styles.titleHeader}>
              {t('pages.locationForm.headers.sygic')}
            </h4>
            <hr className={styles.splitLine} />
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.sygic.label')}
              name="sygic_id"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.nameLocal.label')}
              name="name_local"
              tooltip={t('pages.locationForm.tooltips.nameLocal')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.level.label')}
              name="level"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.rating.label')}
              name="rating"
              tooltip={t('pages.locationForm.tooltips.rating')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.tagKeys.label')}
              name="tag_keys"
            >
              <Select
                mode="tags"
                placeholder={t('pages.locationForm.fields.tagKeys.placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.categories.label')}
              name="categories"
            >
              <Select
                mode="multiple"
                options={buildLocationCategorySelectOptions(categories)}
                placeholder={t(
                  'pages.locationForm.fields.categories.placeholder'
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.ratingLocal.label')}
              name="rating_local"
              tooltip={t('pages.locationForm.tooltips.ratingLocal')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.hotelStarRating.label')}
              name="hotel_star_rating"
              tooltip={t('pages.locationForm.tooltips.hotelStarRating')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.customerRating.label')}
              name="customer_rating"
              tooltip={t('pages.locationForm.tooltips.customerRating')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('pages.locationForm.fields.quadKey.label')}
              name="quad_key"
              tooltip={t('pages.locationForm.tooltips.quadKey')}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={24}>
            <Form.Item
              label={t('pages.locationForm.fields.perex.label')}
              name="perex"
              tooltip={t('pages.locationForm.tooltips.perex')}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={24}>
            {queryResult?.data?.data.place_parents && (
              <Form.Item
                label={t('pages.locationForm.fields.parents.label')}
                name="place_parents"
              >
                <ParentTree />
              </Form.Item>
            )}
          </Col>
        </Row>
        {queryResult?.isSuccess && queryResult?.data?.data.location && (
          <Row gutter={[40, 16]}>
            <Col span={24}>
              <h4 className={styles.titleHeader}>
                {t('pages.locationForm.headers.geometry')}
              </h4>
              <hr className={styles.splitLine} />
            </Col>
          </Row>
        )}
        <Row gutter={[40, 16]}>
          <Col span={24}>
            {queryResult?.isSuccess && queryResult?.data?.data.location && (
              <Form.Item label={''} name="location">
                <MapBox
                  position={[
                    {
                      key: '',
                      location: [
                        queryResult?.data?.data.location.lat,
                        queryResult?.data?.data.location.lng
                      ]
                    }
                  ]}
                  widthMap={'100%'}
                  heightMap={600}
                  edit={false}
                  polygon={{
                    type: GeoJsonTypes.FEATURE,
                    geometry: queryResult?.data?.data?.geometry
                  }}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={24}>
            <Form.Item
              label={t('pages.locationForm.fields.activated.label')}
              name="is_active"
              valuePropName="checked"
              className={styles.status}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LocationFYI />
    </FormAction>
  );
};
