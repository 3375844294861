import { Switch, useTable } from '@pankod/refine-antd';
import { CrudFilters, HttpError, useUpdate } from '@pankod/refine-core';
import { ActionCell } from 'components/ActionCell';
import { TitleFilter } from 'components/TitleFilter';
import { ListPageLayout } from 'components/layout/ListPageLayout';
import { useCountries } from 'hooks/useCountries';
import { useSyncStatusSubscription } from 'hooks/useSyncStatusSubscription';
import { useTranslation } from 'react-i18next';
import { buildCountrySelectOptions } from 'utils/buildCountrySelectOptions';
import { buildFilterItem } from 'utils/buildFilterItem';
import { buildTableColumn } from 'utils/buildTableColumn';
import { Suppliers } from 'utils/hasura';

interface SuppliertListProps {
  isHotelSupplier?: boolean;
}

export const SupplierList: React.FC<SuppliertListProps> = ({
  isHotelSupplier = true
}: SuppliertListProps) => {
  const { mutate } = useUpdate<Suppliers>();
  const { t } = useTranslation();
  const countries = useCountries();

  const { syncStatusData } = useSyncStatusSubscription({
    syncType: 'accommodation'
  });

  const fields = [
    'id',
    'code',
    'name',
    'address',
    'address4',
    'address5',
    'deleted_at',
    'is_active',
    'is_recommended'
  ];

  const { tableProps, searchFormProps } = useTable<
    Suppliers,
    HttpError,
    {
      code: string;
      name: string;
      address: string;
      address4: string;
      address5: string;
    }
  >({
    resource: 'suppliers',
    initialSorter: [
      {
        field: 'code',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'product.product_type',
        operator: 'eq',
        value: isHotelSupplier ? 'ACCOMMODATION' : 'CRUISE_ACCOMMODATION'
      },
      {
        field: 'deleted_at',
        operator: 'null',
        value: true
      }
    ],
    onSearch: params => {
      const filters: CrudFilters = [];
      const { code, name, address, address4, address5 } = params;

      filters.push(
        buildFilterItem('code', 'contains', code),
        buildFilterItem('name', 'contains', name),
        buildFilterItem('address', 'contains', address),
        buildFilterItem('address4', 'contains', address4),
        // FIXME: should use country code field instead of address5 field
        // when the country code is available in products table
        buildFilterItem('address5', 'contains', address5)
      );

      return filters;
    },
    metaData: {
      fields
    }
  });

  tableProps.columns = [
    buildTableColumn(
      <TitleFilter
        name="code"
        title={
          isHotelSupplier
            ? t('pages.hotelList.code')
            : t('pages.cruiseList.code')
        }
        filterType="string"
      />,
      'code'
    ),
    buildTableColumn(
      <TitleFilter
        name="name"
        title={
          isHotelSupplier
            ? t('pages.hotelList.name')
            : t('pages.cruiseList.name')
        }
      />,
      'name'
    ),
    buildTableColumn(
      <TitleFilter
        name="address"
        title={
          isHotelSupplier
            ? t('pages.hotelList.address')
            : t('pages.cruiseList.address')
        }
      />,
      'address'
    ),
    buildTableColumn(
      <TitleFilter
        name="address4"
        title={
          isHotelSupplier
            ? t('pages.hotelList.address4')
            : t('pages.cruiseList.address4')
        }
      />,
      'address4'
    ),
    buildTableColumn(
      <TitleFilter
        name="address5"
        title={
          isHotelSupplier
            ? t('pages.hotelList.address5')
            : t('pages.cruiseList.address5')
        }
        filterType="dropdown"
        options={buildCountrySelectOptions(countries)}
      />,
      'address5'
    ),
    buildTableColumn(
      t('recommendation'),
      'is_recommended',
      true,
      (value, supplier) => (
        <Switch
          checked={!!value}
          onChange={checked => {
            mutate({
              resource: 'suppliers',
              id: supplier.id,
              values: {
                is_recommended: checked
              }
            });
          }}
        />
      )
    ),
    buildTableColumn(
      t('pages.productList.active'),
      'is_active',
      true,
      (value, supplier) => (
        <Switch
          checked={!!value}
          onChange={checked => {
            mutate({
              resource: 'suppliers',
              id: supplier.id,
              values: {
                is_active: checked
              }
            });
          }}
        />
      )
    ),
    buildTableColumn(t('actions'), 'id', false, (_, record) => (
      <ActionCell
        id={record.id}
        deleteResource="suppliers"
        actions={['edit']}
      />
    ))
  ];

  return (
    <ListPageLayout
      pageHeaderProps={{
        extra: syncStatusData && t(syncStatusData.messageKey)
      }}
      tableProps={tableProps}
      searchFormProps={searchFormProps}
    />
  );
};
