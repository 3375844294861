export interface IPositionProps {
  position: IPositionInfo[];
  widthMap: string | number;
  heightMap: string | number;
  edit: boolean;
  polygon?: IGeoJsonTypes;
  setLocationInputs?: (type: string, value: string) => void;
}
export interface IPositionInfo {
  key: string;
  location: any;
}
export interface IGeoJsonTypes {
  type: GeoJsonTypes;
  geometry: IGeometricType;
}
export interface IGeometricType {
  type: GeoJsonTypes;
  coordinates: any[];
}

export enum GeoJsonTypes {
  POINT = 'Point',
  MULTIPOINT = 'MultiPoint',
  LINESTRING = 'LineString',
  MULTILINESTRING = 'MultiLineString',
  POLYGON = 'Polygon',
  MULTIPOLYGON = 'MultiPolygon',
  GEOMETRYCOLLECTION = 'GeometryCollection',
  FEATURE = 'Feature',
  FEATURECOLLECTION = 'FeatureCollection'
}

export enum LocationInputsTypes {
  STARTLOCATION = 'S',
  ENDLOCATION = 'E'
}
