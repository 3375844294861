import { Icons } from '@pankod/refine-antd';

import { UserList } from './UserList';
import { UserCreate } from './UserCreate';
import { UserEdit } from './UserEdit';

export const users = {
  name: 'users',
  icon: <Icons.TeamOutlined />,
  list: UserList,
  create: UserCreate,
  edit: UserEdit
};
