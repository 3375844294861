import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';
import { GraphqlService } from 'services/graphql';
import { Products } from 'utils/hasura';

import { ITourCodesType } from '../models/product';

const { client: graphqlClient } = GraphqlService;

const GET_PRODUCT_TOUR_CODES = gql`
  query GetProductTourCodes {
    products(distinct_on: [code6], where: { code6: { _is_null: false } }) {
      code6
      code6_description
    }
  }
`;

export function useTourCodes(): ITourCodesType[] {
  const [productTourCodes, setProductTourCodes] = useState<ITourCodesType[]>(
    []
  );

  useEffect(() => {
    graphqlClient
      .request<{
        products: Products[];
      }>(GET_PRODUCT_TOUR_CODES)
      .then(({ products }) => {
        setProductTourCodes(
          products
            .map(item => ({
              code6: item.code6 || '',
              code6_description: item.code6_description || ''
            }))
            .filter(item => !!item.code6)
        );
      })
      .catch(err => Promise.reject(err.message));
  }, []);

  return productTourCodes;
}
