import { gql } from 'graphql-request';

export const GET_LOCATION_CATEGORIES = gql`
  query GetCountries {
    locations(where: { categories: { _is_null: false } }) {
      categories
    }
  }
`;

export const UPDATE_PRODUCT_BY_ID = gql`
  mutation UpdateProduct(
    $pk_columns: products_pk_columns_input!
    $set: products_set_input
  ) {
    update_products_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const UPDATE_TRANSPORTATION_BY_ID = gql`
  mutation UpdateTransportation(
    $pk_columns: transportations_pk_columns_input!
    $set: transportations_set_input
  ) {
    update_transportations_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const FILE_UPLOAD = gql`
  mutation FileUpload($files: [UploadedFile!]!, $upload_type: UploadType!) {
    fileUpload(files: $files, upload_type: $upload_type) {
      bucketUrl
      files
    }
  }
`;

export const GET_PROVIDER_SERVERS = gql`
  query GetProviderServers {
    provider_servers(where: { is_active: { _eq: true } }) {
      booking_url
      country
      country_name
      created_at
      id
      image_url
      product_url
      provider_id
      updated_at
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query GetAllOrganizations {
    organizations {
      id
      name
    }
  }
`;
export const GET_ORGANIZATION_BY_ID = gql`
  query GetOrganizationById($organization_id: Int!) {
    organizations_by_pk(id: $organization_id) {
      id
      code
      name
      is_public_agency
    }
  }
`;
