import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Checkbox, Button } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';

import { LoginLocalInput, LoginInput } from 'models/auth';

interface LoginFormProps {
  onSubmit: (input: LoginInput) => void;
}

const { useForm } = Form;

export const LoginForm: FC<LoginFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const [form] = useForm<LoginLocalInput>();

  const handleSubmit = (values: LoginLocalInput) => {
    onSubmit({ loginType: 'PASSWORD', local: { ...values } });
  };

  return (
    <Form<LoginLocalInput>
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      initialValues={{
        remember: false
      }}
    >
      <Form.Item
        name="email"
        label={t('pages.login.email')}
        rules={[{ required: true, type: 'email' }]}
      >
        <Input size="large" placeholder={t('pages.login.email')} />
      </Form.Item>
      <Form.Item
        className="mb-3"
        name="password"
        label={t('pages.login.password')}
        rules={[{ required: true }]}
      >
        <Input type="password" placeholder="●●●●●●●●" size="large" />
      </Form.Item>
      <div className="mb-3">
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>{t('pages.login.remember')}</Checkbox>
        </Form.Item>

        <Link to="/">{t('pages.login.forgotPassword')}</Link>
      </div>
      <Button type="primary" size="large" htmlType="submit" block>
        {t('pages.login.signin')}
      </Button>
    </Form>
  );
};
