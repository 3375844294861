// eslint-disable-next-line import/no-extraneous-dependencies
import * as gql from 'gql-query-builder';
import {
  ThirdPartyAPI,
  OrganizationsRemovedInput,
  OrganizationsThirdPartiesInput
} from 'models';
import { BaseKey, MetaDataQuery } from '@pankod/refine-core';

import { GraphqlService } from 'services/graphql';
import { formatMutationInput } from '../utils';
import { commonProvider } from './commonProvider';

const { client: graphqlClient } = GraphqlService;

const dataProvider = commonProvider(graphqlClient);

dataProvider.getOne = async ({ resource, id, metaData }) => {
  const operation = `${metaData?.operation ?? resource}_by_pk`;

  const { query, variables } = gql.query({
    operation,
    variables: {
      id: { value: id, type: 'Int', required: true },
      ...metaData?.variables
    },
    fields: metaData?.fields
  });

  const response = await graphqlClient.request(query, variables);
  const [provider] =
    response?.organizations_by_pk?.organization_providers || [];
  const [thirdParties] =
    response?.organizations_by_pk?.organization_third_parties || [];
  return {
    data: {
      ...response.organizations_by_pk,
      product_visibility: response.organizations_by_pk.product_visibility || [],
      rate_code_priorities:
        response.organizations_by_pk.rate_code_priorities || [],
      'organization_providers.data.server_id': provider?.server_id,
      'organization_providers.data.server_password': provider?.server_password,
      'organization_third_parties.data.account': thirdParties?.account,
      'organization_third_parties.data.password': thirdParties?.password
    }
  };
};

const { update, create } = dataProvider;

dataProvider.create = async ({ resource, metaData, variables }) => {
  let customVariables: any = {};
  customVariables = formatMutationInput({
    ...variables,
    'organization_providers.data.provider_id': 1
  });

  if (
    customVariables?.organization_third_parties.data.account ||
    customVariables?.organization_third_parties.data.password
  ) {
    customVariables = formatMutationInput({
      ...customVariables,
      'organization_third_parties.data.type': ThirdPartyAPI.AMADEUS
    });
  } else {
    const {
      organization_third_parties: organizationThirdParties,
      ...removeThirdParty
    } = customVariables;
    customVariables = removeThirdParty;
  }
  const operation = metaData?.mutation?.operation;

  if (!operation) {
    return create({ resource, metaData, variables });
  }

  const metaDataVariables = metaData?.mutation?.variables;
  const [input] = Object.keys(metaDataVariables || {});

  const { query } = gql.mutation({
    operation,
    variables: metaDataVariables,
    fields: metaData?.fields
  });
  const response = await graphqlClient.request(query, {
    [input]: customVariables
  });

  /**
   * Insert Organization Branding with organization id
   */
  if (response) {
    const { query: newQuery } = gql.mutation({
      operation: 'insert_organization_brandings_one',
      variables: {
        object: {
          type: 'organization_brandings_insert_input!'
        }
      },
      fields: [
        'organization_id',
        'logo',
        'primary_color',
        'secondary_primary_color',
        'secondary_color'
      ]
    });

    await graphqlClient.request(newQuery, {
      object: {
        organization_id: response[operation].id,
        logo: null,
        primary_color: null,
        secondary_primary_color: null,
        secondary_color: null
      }
    });
  }

  return {
    data: response[operation]
  };
};

async function updateOrCreateOrganizationThirdParties(
  resource: string,
  id: BaseKey,
  organizationThirdPartiesVariables: OrganizationsThirdPartiesInput,
  metaData?: MetaDataQuery
) {
  const organizationThirdPartiesReponse = await dataProvider.getOne({
    resource,
    id,
    metaData
  });
  const organizationsThirdPartiesID =
    organizationThirdPartiesReponse?.data?.organization_third_parties[0]?.id;
  if (organizationsThirdPartiesID) {
    await update({
      resource: 'organization_third_parties',
      metaData: {
        fields: ['id']
      },
      variables: organizationThirdPartiesVariables,
      id: organizationsThirdPartiesID
    });
  } else {
    await create({
      resource: 'organization_third_parties',
      metaData: {
        fields: ['id']
      },
      variables: {
        ...organizationThirdPartiesVariables,
        organization_id: id,
        type: ThirdPartyAPI.AMADEUS
      }
    });
  }
}

dataProvider.update = async ({ resource, metaData, variables, id }) => {
  const operation = metaData?.mutation?.operation;
  let organizationsVariables = formatMutationInput(variables as any);
  const organizationProvidersVariables = {
    server_id: organizationsVariables?.organization_providers?.data?.server_id,
    server_password:
      organizationsVariables?.organization_providers?.data?.server_password
  };
  const organizationThirdPartiesVariables: OrganizationsThirdPartiesInput = {
    account: organizationsVariables?.organization_third_parties?.data?.account,
    password: organizationsVariables?.organization_third_parties?.data?.password
  };
  const {
    organization_third_parties: organizationThirdParties,
    organization_providers: organizationProviders,
    ...removeThirdParty
  } = organizationsVariables as OrganizationsRemovedInput;
  organizationsVariables = removeThirdParty;
  if (!operation) {
    const organizationProvidersReponse = await dataProvider.getOne({
      resource,
      id,
      metaData
    });
    const organizationsProviderID =
      organizationProvidersReponse?.data?.organization_providers[0]?.id;
    await update({
      resource: 'organization_providers',
      metaData: {
        fields: ['id']
      },
      variables: organizationProvidersVariables,
      id: organizationsProviderID
    });
    if (
      organizationThirdPartiesVariables?.account ||
      organizationThirdPartiesVariables?.password
    ) {
      await updateOrCreateOrganizationThirdParties(
        resource,
        id,
        organizationThirdPartiesVariables,
        metaData
      );
    }
    return update({
      resource,
      metaData,
      variables: organizationsVariables,
      id
    });
  }
  const metaDataVariables = metaData?.mutation?.variables;
  const [input] = Object.keys(metaDataVariables || {});

  const { query } = gql.mutation({
    operation,
    variables: metaDataVariables,
    fields: metaData?.fields
  });
  const response = await graphqlClient.request(query, {
    [input]: { ...organizationsVariables, id: parseInt(id as string, 10) }
  });

  return {
    data: response[operation]
  };
};

export const organizationsDataProvider = dataProvider;
