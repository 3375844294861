import { Icons } from '@pankod/refine-antd';

import { TransportationEdit } from './TransportationEdit';
import { TransportationList } from './TransportationList';

export const transportations = {
  name: 'transportations',
  icon: <Icons.CarOutlined />,
  list: TransportationList,
  edit: TransportationEdit
};
