class StorageService {
  get<T>(key: string): T | null {
    try {
      const raw = window.localStorage.getItem(key);
      return raw ? (JSON.parse(raw) as T) : null;
    } catch {
      return null;
    }
  }

  set(key: string, value: any): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string): void {
    window.localStorage.removeItem(key);
  }

  clear(): void {
    window.localStorage.clear();
  }

  sessionGet<T>(key: string): T | null {
    try {
      const raw = window.sessionStorage.getItem(key);
      return raw ? (JSON.parse(raw) as T) : null;
    } catch {
      return null;
    }
  }

  sessionSet(key: string, value: any): void {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  sessionRemove(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  sessionClear(): void {
    window.sessionStorage.clear();
  }
}
const singleton = new StorageService();
export { singleton as StorageService };
