import { useSubscription } from '@pankod/refine-core';
import { SyncStatus } from 'constants/common';
import { useCallback, useState } from 'react';
import { SyncStatuses } from 'utils/hasura';

type SyncType = 'tour' | 'transportation' | 'accommodation' | 'supplier';

interface ISyncStatusSubscriptionProps {
  syncType: SyncType;
}

export const useSyncStatusSubscription = ({
  syncType
}: ISyncStatusSubscriptionProps) => {
  const [syncStatusData, setSyncStatusData] = useState<{
    syncType: SyncType;
    messageKey: string;
  }>();

  const onLiveEvent = useCallback(
    (liveEvent: any) => {
      const data = liveEvent as SyncStatuses[];

      const syncFnNames: Record<SyncType, string> = {
        accommodation: 'SYNC_ACCOMMODATIONS',
        supplier: 'SYNC_SUPPLIERS',
        tour: 'SYNC_TOURS',
        transportation: 'SYNC_TRANSPORTATIONS'
      };

      const syncFnName = syncFnNames[syncType];
      const syncs = data.filter(s => s.function_name === syncFnName);

      const messageKeys: Record<SyncStatus, string> = {
        PENDING: 'sync.warningStatus.pending',
        RUNNING: 'sync.warningStatus.running',
        COMPLETED: 'sync.warningStatus.completed',
        FAILED: 'sync.warningStatus.failed'
      };

      const hasRunning = syncs.some(s => s.status === SyncStatus.RUNNING);
      const hasPending = syncs.some(s => s.status === SyncStatus.PENDING);
      const hasCompleted = syncs.some(s => s.status === SyncStatus.COMPLETED);
      const hasFailed = syncs.some(s => s.status === SyncStatus.FAILED);

      let msgKey = '';
      switch (true) {
        case hasRunning:
          msgKey = messageKeys[SyncStatus.RUNNING];
          break;
        case hasPending:
          msgKey = messageKeys[SyncStatus.PENDING];
          break;
        case hasCompleted:
          msgKey = messageKeys[SyncStatus.COMPLETED];
          break;
        case hasFailed:
          msgKey = messageKeys[SyncStatus.FAILED];
          break;
        default:
      }

      setSyncStatusData({
        syncType,
        messageKey: msgKey
      });
    },
    [syncType]
  );

  useSubscription({
    channel: 'sync_statuses',
    params: {
      metaData: {
        operation: 'sync_statuses',
        fields: ['id', 'function_name', 'status', 'created_at', 'updated_at']
      },
      subscriptionType: 'useList',
      resource: 'sync_statuses'
    },
    onLiveEvent
  });

  return {
    syncStatusData
  };
};
