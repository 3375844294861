import { Icons } from '@pankod/refine-antd';

import { HotelList } from './HotelList';
import { HotelEdit } from './HotelEdit';

export const hotels = {
  name: 'hotels',
  icon: <Icons.HomeOutlined />,
  list: HotelList,
  edit: HotelEdit
};
