import { Icons } from '@pankod/refine-antd';

import { CruiseList } from './CruiseList';
import { CruiseEdit } from './CruiseEdit';

export const cruises = {
  name: 'cruises',
  icon: <Icons.BankOutlined />,
  list: CruiseList,
  edit: CruiseEdit
};
