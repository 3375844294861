/* eslint-disable prefer-promise-reject-errors */
import { AuthProvider } from '@pankod/refine-core';

import { LoginInput } from 'models/auth';
import { AuthService, AUTH_TOKEN_KEY } from 'services/auth';
import { GraphqlService } from 'services/graphql';
import { StorageService } from 'services/storage';
import i18next from 'i18next';
import { parseJwtToken } from '../utils/parseJwtToken';

const ADMIN_ROLE = 'SUPER_ADMIN';
const HASURA_CLAIMS = 'https://hasura.io/jwt/claims';
const HASURA_DEFAULT_ROLE = 'x-hasura-default-role';

const { client: graphqlClient } = GraphqlService;

export const authProvider: AuthProvider = {
  login: async (input: LoginInput) => {
    const variables = AuthService.buildVariables(input);

    return AuthService.login(variables)
      .then(data => {
        const { token } = data.login;
        const decodedToken = parseJwtToken(token);
        const role = decodedToken?.[HASURA_CLAIMS]?.[HASURA_DEFAULT_ROLE];

        if (role === ADMIN_ROLE) {
          StorageService.set(AUTH_TOKEN_KEY, token);
          graphqlClient.setHeader('authorization', `Bearer ${token}`);
          return Promise.resolve('/users');
        }

        return Promise.reject({ isPermission: true });
      })
      .catch(err => {
        if (err.isPermission) {
          return Promise.reject({
            message: i18next.t('common:pages.login.errorPermission')
          });
        }

        return Promise.reject(err.message);
      });
  },
  logout: () => {
    const token = StorageService.get(AUTH_TOKEN_KEY);

    if (token) {
      StorageService.remove(AUTH_TOKEN_KEY);
    }

    graphqlClient.setHeader('authorization', '');

    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = StorageService.get(AUTH_TOKEN_KEY);

    if (token) {
      graphqlClient.setHeader('authorization', `Bearer ${token}`);
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => {
    const token = StorageService.get<string>(AUTH_TOKEN_KEY) || '';
    const decodedToken = parseJwtToken(token);
    const role = decodedToken?.[HASURA_CLAIMS]?.[HASURA_DEFAULT_ROLE];

    if (role) {
      return Promise.resolve(role);
    }

    return Promise.reject();
  },
  getUserIdentity: async () => {
    const token = StorageService.get(AUTH_TOKEN_KEY);

    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: 1
    });
  }
};
