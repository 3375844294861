/* eslint-disable react/destructuring-assignment */
import { useState, useRef, useMemo, useEffect } from 'react';
import { MapContainer, Marker, TileLayer, GeoJSON } from 'react-leaflet';
import { IPositionProps, IPositionInfo } from 'models';
import L, { LatLngLiteral } from 'leaflet';
import 'leaflet.icon.glyph';
import Config from '../../configs/config.json';

function DraggableMarker(
  key: string,
  center: LatLngLiteral,
  edit: boolean,
  setLocationInputs: ((type: string, value: string) => void) | undefined
) {
  const [position, setPosition] = useState(center);
  const markerRef = useRef<any>(null);

  useEffect(() => {
    setPosition(center);
  }, [center.lat, center.lng]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker !== null && setLocationInputs) {
          const latLng = marker.getLatLng();
          setPosition(latLng);
          setLocationInputs(key, `${latLng.lat},${latLng.lng}`);
        }
      }
    }),
    []
  );
  return (
    <Marker
      key={key}
      draggable={edit}
      icon={L.icon.glyph({ glyph: key })}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    />
  );
}

export const MapBox: React.FC<IPositionProps> = ({
  position,
  widthMap,
  heightMap,
  edit,
  polygon,
  setLocationInputs
}: IPositionProps) => (
  <MapContainer
    center={position[0].location}
    zoom={13}
    style={{ width: widthMap, height: heightMap }}
  >
    <TileLayer
      attribution='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      url={`https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=${Config.REACT_APP_MAPBOX_TOKEN}`}
    />
    {polygon && <GeoJSON data={polygon} />}

    {position.length > 0 &&
      position.map(
        (marker: IPositionInfo) =>
          DraggableMarker(
            marker?.key,
            {
              lat: marker?.location[0],
              lng: marker?.location[1]
            },
            edit,
            setLocationInputs
          )
        // eslint-disable-next-line function-paren-newline
      )}
  </MapContainer>
);
