import React from 'react';
import { useLogin } from '@pankod/refine-core';
import { Row, Col, AntdLayout, Card, Typography } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { GoogleLoginResponse } from 'react-google-login';

import { LoginInput } from 'models/auth';

import { LoginForm } from './LoginForm';

import styles from './index.module.scss';

const { Title } = Typography;

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  const { mutate: login } = useLogin<LoginInput | GoogleLoginResponse>();

  const CardTitle = (
    <Title level={3} className="title">
      {t('pages.login.title')}
    </Title>
  );

  return (
    <AntdLayout className={styles.layout}>
      <Row className="h-screen" justify="center" align="middle">
        <Col xs={22}>
          <div className={styles.container}>
            <div className={styles.imageContainer}>
              <img src="/images/svg/logo.svg" alt="Exo Create" />
            </div>
            <Card
              bordered={false}
              title={CardTitle}
              headStyle={{ borderBottom: 0 }}
            >
              <LoginForm onSubmit={login} />
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
