import { Space, EditButton } from '@pankod/refine-antd';

export interface EditActionProps {
  id: number;
}

export const EditAction: React.FC<EditActionProps> = ({
  id
}: EditActionProps) => (
  <Space>
    <EditButton size="small" recordItemId={id} hideText />
  </Space>
);
