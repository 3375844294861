/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Button,
  Col,
  Create,
  Edit,
  Form,
  Input,
  Row,
  Select,
  Typography,
  useForm
} from '@pankod/refine-antd';
import { IResourceComponentsProps, useNotification } from '@pankod/refine-core';
import { ThirdPartyAPI } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GraphqlService } from 'services/graphql';
import { Organizations } from 'utils/hasura';
import { VERIFY_API_KEY } from '../graphql';

const { client: graphqlClient } = GraphqlService;

export const OrganizationForm: React.FC<IResourceComponentsProps> = () => {
  const { action } = useParams();
  const { formProps, saveButtonProps } = useForm<Organizations>({
    dataProviderName: 'organizationsDataProvider',
    metaData: {
      mutation: {
        operation: action === 'create' ? 'insert_organizations_one' : '',
        variables: {
          object: {
            type: 'organizations_insert_input!'
          }
        }
      },
      fields: [
        'id',
        'name',
        'code',
        'description',
        'product_visibility',
        'rate_code_priorities',
        'organization_providers {id server_id server_password}',
        'organization_third_parties {id account password}'
      ]
    },
    redirect: 'list'
  });
  const { Title, Paragraph } = Typography;
  const { t } = useTranslation();
  const productVisibilityOptions = [
    {
      value: 'AV',
      label: 'AV   '
    },
    {
      value: 'TR',
      label: 'TR'
    },
    {
      value: 'X1',
      label: 'X1'
    },
    {
      value: 'WW',
      label: 'WW'
    },
    {
      value: 'dfgdgf',
      label: 'dfgdgf'
    },
    {
      value: '23',
      label: '23'
    }
  ];
  const rateCodePrioritiesOptions = [
    {
      value: 'english',
      label: 'English   '
    },
    {
      value: 'french',
      label: 'French'
    },
    {
      value: 'italian',
      label: 'Italian'
    },
    {
      value: 'spanish',
      label: 'Spanish'
    },
    {
      value: 'german',
      label: 'German'
    }
  ];
  let FormAction = Create;
  if (action === 'edit') {
    FormAction = Edit;
  }
  const { open } = useNotification();

  const testUserCredentials = (type: string): void => {
    let payload = {};
    switch (type) {
      case ThirdPartyAPI.TOURPLAN:
        payload = {
          type,
          serverId: formProps.form?.getFieldValue(
            'organization_providers.data.server_id'
          ),
          serverPassword: formProps.form?.getFieldValue(
            'organization_providers.data.server_password'
          )
        };
        break;
      case ThirdPartyAPI.AMADEUS:
        payload = {
          type,
          apiKey: formProps.form?.getFieldValue(
            'organization_third_parties.data.account'
          ),
          apiPassword: formProps.form?.getFieldValue(
            'organization_third_parties.data.password'
          )
        };
        break;
      default:
        payload = {};
        break;
    }
    graphqlClient
      .request(VERIFY_API_KEY, payload)
      .then(({ verifyApiKey }) => {
        open &&
          open({
            message: verifyApiKey.isAuthorized ? 'Agent test successful! ' : '',
            type: 'success'
          });
      })
      .catch(error => {
        open &&
          open({
            message:
              error?.response?.errors[0]?.extensions?.internal?.response?.body
                ?.message,
            type: 'error'
          });
      });
  };
  return (
    <FormAction saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Title level={4}>{t('pages.organizationCreate.basics')}</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.organizationCreate.companyName')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('form.fields.errors.name.required')
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('pages.organizationCreate.description')}
              name="description"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.organizationCreate.productVisibility')}
              name="product_visibility"
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="select countries"
                options={rateCodePrioritiesOptions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('pages.organizationCreate.rateCodePriorites')}
              name="rate_code_priorities"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                options={productVisibilityOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Title level={4}>Integrations:</Title>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col span={12}>
            <Form.Item
              label={t('pages.organizationCreate.tourplanAgentId')}
              name="organization_providers.data.server_id"
              rules={[
                {
                  required: true,
                  message: t('form.fields.errors.tourplanAgentId.required')
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              label={t('pages.organizationCreate.tourplanPassword')}
              name="organization_providers.data.server_password"
              rules={[
                {
                  required: true,
                  message: t('form.fields.errors.tourplanPassword.required')
                }
              ]}
            >
              <Input placeholder="input password" />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              onClick={() => testUserCredentials(ThirdPartyAPI.TOURPLAN)}
            >
              {t('pages.organizationCreate.test')}
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col span={12}>
            <Form.Item
              label={t('pages.organizationCreate.tourplanCode')}
              name="code"
              rules={[
                {
                  required: true,
                  message: t('form.fields.errors.tourCode.required')
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Paragraph>
              {t(
                'pages.organizationCreate.theCodeUsedInTheTourplanSystemAndStayPermantlyCanNotChangeAfterCreated'
              )}
            </Paragraph>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col span={12}>
            <Form.Item
              label={t('pages.organizationCreate.amadeusAPIKey')}
              name="organization_third_parties.data.account"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              label={t('pages.organizationCreate.amadeusAPISecret')}
              name="organization_third_parties.data.password"
            >
              <Input.Password placeholder="input password" />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              onClick={() => testUserCredentials(ThirdPartyAPI.AMADEUS)}
            >
              {t('pages.organizationCreate.test')}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormAction>
  );
};
