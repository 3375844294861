import { useTable } from '@pankod/refine-antd';
import { CrudFilters, HttpError } from '@pankod/refine-core';
import { DeleteAction } from 'components/DeleteAction';
import { EditAction } from 'components/EditAction';
import { TitleFilter } from 'components/TitleFilter';
import { ListPageLayout } from 'components/layout/ListPageLayout';
import { useTranslation } from 'react-i18next';
import { buildFilterItem } from 'utils/buildFilterItem';
import { buildTableColumn } from 'utils/buildTableColumn';
import { Organizations } from 'utils/hasura';

export const OrganizationList: React.FC<any> = () => {
  const { t } = useTranslation();

  const { tableProps, searchFormProps } = useTable<
    Organizations,
    HttpError,
    {
      name: string;
      description: string;
      code: string;
    }
  >({
    resource: 'organizations',
    initialSorter: [
      {
        field: 'name',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted_at',
        operator: 'null',
        value: true
      }
    ],
    onSearch: params => {
      const filters: CrudFilters = [];
      const { name, description, code } = params;

      filters.push(
        buildFilterItem('name', 'contains', name),
        buildFilterItem('description', 'contains', description),
        buildFilterItem('code', 'contains', code)
      );

      return filters;
    },
    metaData: {
      fields: ['id', 'name', 'description', 'code']
    }
  });

  tableProps.columns = [
    buildTableColumn(
      <TitleFilter name="name" title={t('pages.organizationList.name')} />,
      'name'
    ),
    buildTableColumn(
      <TitleFilter
        name="description"
        title={t('pages.organizationList.description')}
        filterType="string"
      />,
      'description'
    ),
    buildTableColumn(
      <TitleFilter
        name="code"
        title={t('pages.organizationList.tourplanCode')}
      />,
      'code'
    ),
    buildTableColumn('', 'actions', false, (_, record) => (
      <>
        <span style={{ paddingRight: '30px' }}>
          <EditAction id={record.id} />
        </span>
        <DeleteAction id={record.id} />
      </>
    ))
  ];

  return (
    <ListPageLayout tableProps={tableProps} searchFormProps={searchFormProps} />
  );
};
