import { Products } from 'utils/hasura';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as gqlQueryBuilder from 'gql-query-builder';

import {
  UPDATE_PRODUCT_BY_ID,
  UPDATE_TRANSPORTATION_BY_ID
} from 'graphql-queries';
import { GraphqlService } from 'services/graphql';
import { handleImageUpload } from 'utils/handleImageUpload';
import { commonProvider } from './commonProvider';

const GUIDE_LANGUAGE_SEPARATOR = ', ';

const { client: graphqlClient } = GraphqlService;
const dataProvider = commonProvider(graphqlClient);

dataProvider.getOne = async ({ id, metaData }) => {
  const operation = 'products_by_pk';

  const { query } = gqlQueryBuilder.query({
    operation,
    variables: {
      id: { type: 'Int', required: true }
    },
    fields: metaData?.fields
  });

  const response = await graphqlClient.request(query, { id });
  const product = response[operation];
  const { transportation } = product;
  const guide = transportation?.guide;

  return {
    data: {
      ...product,
      images: product.images?.map((url: string) => ({
        thumbUrl: url
      })),
      transportation: {
        ...(transportation || {}),
        guide: guide?.split(GUIDE_LANGUAGE_SEPARATOR) || []
      }
    }
  };
};

dataProvider.update = async ({ variables, id }) => {
  const { transportation: transportationParamsWithId, ...productParams } =
    (variables as unknown as Products) || {};

  const {
    id: transportationId,
    guide,
    ...transportationParams
  } = transportationParamsWithId || {};

  const imageUrls = await handleImageUpload(
    productParams.images || [],
    'PRODUCT'
  );

  productParams.images = `{${imageUrls.join(',')}}`;

  const { update_products_by_pk: product } = await graphqlClient.request(
    UPDATE_PRODUCT_BY_ID,
    {
      pk_columns: { id },
      _set: productParams
    }
  );

  if (transportationId) {
    const guideLanguages = Array.isArray(guide)
      ? guide.join(GUIDE_LANGUAGE_SEPARATOR)
      : guide;

    const { update_transportations_by_pk: transportation } =
      await graphqlClient.request(UPDATE_TRANSPORTATION_BY_ID, {
        pk_columns: { id: transportationId },
        _set: {
          ...transportationParams,
          guide: guideLanguages
        }
      });

    product.transportation = transportation;
  }

  return {
    data: product
  };
};

export const transportationDataProvider = dataProvider;
