export const checkLocationIsValid = (value: string): string[] => {
  if (value && value.split(',').length >= 2 && value.split(',').length < 3) {
    const locationArray = value.split(',');
    let flag = true;
    locationArray.forEach(element => {
      if (!Number(element)) {
        flag = false;
      }
    });
    if (flag) {
      return locationArray;
    }
    return ['0', '0'];
  }
  return ['0', '0'];
};
