import { Checkbox, Table, useTable } from '@pankod/refine-antd';
import { HttpError } from '@pankod/refine-core';
import { useTranslation } from 'react-i18next';
import { ProductMapping, Products } from 'utils/hasura';

import { buildTableColumn } from 'utils';

export interface ISupplierAccommodationProps {
  supplierId: number;
  isHotelSupplier?: boolean;
}

export const SupplierAccommodation: React.FC<ISupplierAccommodationProps> = ({
  supplierId,
  isHotelSupplier
}: ISupplierAccommodationProps) => {
  const { t } = useTranslation();
  const { tableProps } = useTable<Products, HttpError>({
    resource: 'products',
    metaData: {
      fields: [
        'id',
        'supplier_id',
        'product_code',
        'name',
        'promotion',
        'is_active',
        // { accommodation: ['rate', 'rate_description'] },
        { product_mappings: [{ provider: ['id', 'name'] }] }
      ]
    },
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'product_type',
        operator: 'eq',
        value: isHotelSupplier ? 'ACCOMMODATION' : 'CRUISE_ACCOMMODATION'
      },
      {
        field: 'supplier_id',
        operator: 'eq',
        value: supplierId
      }
    ]
  });

  const columns = [
    buildTableColumn(
      t('components.hotelRoom.provider'),
      'product_mappings',
      false,
      productMappings =>
        Array.from(
          new Set(
            productMappings?.map((item: ProductMapping) => item.provider.name)
          )
        ).join(', ')
    ),
    buildTableColumn(t('components.hotelRoom.code'), 'product_code', false),
    buildTableColumn(t('pages.hotelEdit.fields.name.label'), 'name', false),
    // buildTableColumn(
    //   t('components.hotelRoom.rate'),
    //   ['accommodation', 'rate_description'],
    //   false
    // ),
    buildTableColumn(
      t('components.hotelRoom.active'),
      'is_active',
      false,
      (checked: boolean) => <Checkbox checked={checked} disabled />
    )
  ];

  return <Table {...tableProps} rowKey="id" columns={columns} />;
};
