import { CrudFilter, CrudOperators } from '@pankod/refine-core';

export function buildFilterItem<T = any>(
  field: string,
  operator: CrudOperators,
  value: T
): CrudFilter {
  let filterValue: string | T = value;

  switch (operator) {
    case 'contains':
      if (value) {
        // Filter records whose field value contains filter value
        filterValue = `%${value}%`;
      }
      break;

    default:
      filterValue = value;
  }
  return {
    field,
    operator,
    value: filterValue || null
  } as CrudFilter;
}
