export enum ThirdPartyAPI {
  TOURPLAN = 'TOURPLAN',
  AMADEUS = 'AMADEUS'
}

export interface OrganizationsRemovedInput {
  organization_third_parties: OrganizationsThirdPartiesInput;
  organization_providers: OrganizationsProvidersInput;
}

export interface OrganizationsThirdPartiesInput {
  id?: number;
  organization_id?: number;
  account: string;
  password: string;
}
export interface OrganizationsProvidersInput {
  id: number;
  provider_id: number;
  server_id: string;
  server_password: string;
}

export interface OrganizationItem {
  id: number;
  name: string;
  isMapping: boolean;
}
