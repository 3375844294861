import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  SaveButton,
  Space,
  Table,
  useForm,
  useTable
} from '@pankod/refine-antd';
import { useDelete } from '@pankod/refine-core';
import { COMMON_DATE_FORMAT } from 'constants/common';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';

const SERVICE_TYPE = {
  ACCOMMODATION: 'Accommodation',
  CRUISE: 'Cruises',
  EXPERIENCE: 'Experiences',
  TRANSPORTATION: 'Transfer',
  MDT: 'Multi-day Tours'
};

enum FYIMessageTypeEnum {
  INFO = 'Information',
  WARNING = 'Warning',
  URGENT_WARNING = 'Urgent warning'
}

type TFYIList = {
  dateFrom: string;
  dateTo: string;
  type: string;
  productType: string[];
  message: string;
};

const MAP_TYPE_TO_DISPLAY = {
  INFO: 'Information',
  WARNING: 'Warning',
  URGENT_WARNING: 'Urgent warning'
};

export const LocationFYI: React.FC = () => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const { id: locationId } = useParams();
  const [mode, setMode] = React.useState<'add' | 'edit' | 'view'>('view');
  const [editingId, setEditingId] = useState(-1);
  const { mutate } = useDelete();

  const { tableProps, tableQueryResult } = useTable({
    resource: 'location_fyi_messages',
    dataProviderName: 'locationsFYIDataProvider',
    metaData: {
      fields: [
        'count',
        {
          data: [
            'id',
            'product_type',
            'message',
            'type',
            'date_from',
            'date_to'
          ]
        }
      ],
      locationId: Number(locationId)
    },
    initialPageSize: 10
  });
  const messages = useMemo(
    () => tableQueryResult?.data?.data || [],
    [tableQueryResult]
  );

  const { formProps, saveButtonProps, form } = useForm<TFYIList>({
    resource: 'location_fyi_messages',
    dataProviderName: 'locationsFYIDataProvider',
    metaData: {
      fields: ['id'],
      locationId: Number(locationId),
      id: editingId
    },
    action: mode === 'add' ? 'create' : 'edit',
    redirect: false,
    onMutationSuccess: () => {
      setMode('view');
      setEditingId(-1);
      form.resetFields();
    }
  });

  const onCreate = () => {
    setMode('add');
  };

  const onEdit = (id: number) => {
    const foundMessage = messages.find(message => message.id === id);
    if (foundMessage) {
      form.setFieldsValue(foundMessage);
      setMode('edit');
      setEditingId(id);
    }
  };

  const onDelete = (id: number) => {
    Modal.confirm({
      title: <div className="text-center">Delete Message</div>,
      icon: false,
      content: (
        <div className="text-center">
          The message will be deleted and the users will no longer see it on EXO
          Create.
        </div>
      ),
      okText: 'Confirm',
      onOk() {
        mutate({
          id: id || '',
          resource: 'location_fyi_messages',
          dataProviderName: 'locationsFYIDataProvider',
          invalidates: ['list']
        });
        if (id === editingId) {
          setMode('view');
          setEditingId(-1);
          form.resetFields();
        }
      }
    });
  };

  const onCancel = () => {
    setMode('view');
    setEditingId(-1);
    form.resetFields();
  };

  return (
    <Form {...formProps} layout="vertical">
      <Row gutter={[40, 16]}>
        <Col span={24}>
          <h4 className={styles.titleHeader}>
            {t('pages.locationForm.FYI.title')}
          </h4>
          <hr className={styles.splitLine} />
        </Col>
      </Row>
      <Row gutter={[40, 16]}>
        <Col span={24}>
          <Table rowKey="id" {...tableProps}>
            <Table.Column
              dataIndex="message"
              title="Message"
              sorter={{ multiple: 2 }}
              render={value => (
                <div className="whitespace-pre-wrap">{value}</div>
              )}
            />
            <Table.Column
              dataIndex="type"
              title="Message Type"
              sorter={{ multiple: 1 }}
              render={(value: keyof typeof MAP_TYPE_TO_DISPLAY) =>
                MAP_TYPE_TO_DISPLAY[value]
              }
              width={150}
            />
            <Table.Column
              dataIndex="productType"
              title="Service Type"
              sorter={{ multiple: 1 }}
              render={value =>
                value
                  ?.map(
                    (v: string) =>
                      SERVICE_TYPE?.[v as keyof typeof SERVICE_TYPE]
                  )
                  .join(', ')
              }
            />
            <Table.Column
              dataIndex="dateFrom"
              title="Date From"
              sorter={{ multiple: 1 }}
              render={value =>
                value ? dayjs(value).format(COMMON_DATE_FORMAT) : '-'
              }
              width={130}
            />
            <Table.Column
              dataIndex="dateTo"
              title="Date To"
              sorter={{ multiple: 1 }}
              render={value =>
                value ? dayjs(value).format(COMMON_DATE_FORMAT) : '-'
              }
              width={130}
            />
            <Table.Column
              dataIndex="id"
              render={(_, record) => (
                <Space size="small">
                  <Button
                    // @ts-ignore
                    onClick={() => onEdit(record?.id)}
                  >
                    {t('buttons.edit')}
                  </Button>
                  <Button
                    // @ts-ignore
                    onClick={() => onDelete(record?.id)}
                  >
                    {t('buttons.delete')}
                  </Button>
                </Space>
              )}
            />
          </Table>
          {mode === 'view' && (
            <Button type="primary" onClick={onCreate}>
              {t('buttons.add-new')}
            </Button>
          )}
        </Col>
      </Row>
      {['add', 'edit'].includes(mode) && (
        <>
          <Row gutter={[40, 16]}>
            <Col span={24}>
              <h3 className="font-semibold">
                {t(`pages.locationForm.FYI.${mode}`)}
              </h3>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <>
                    {t('pages.locationForm.FYI.message-type')}
                    <span className="text-red">&nbsp;*</span>
                  </>
                }
                name="type"
                rules={[
                  {
                    required: true
                  }
                ]}
                requiredMark="optional"
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {Object.entries(FYIMessageTypeEnum).map(entry => (
                      <Radio value={entry[0]}>
                        <div className="flex gap-2">
                          <div>{entry[1]}</div>
                          <img
                            src={`/images/icons/${entry[0].toLowerCase()}.svg`}
                            alt={entry[1]}
                          />
                        </div>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('pages.locationForm.FYI.service-type')}
                name="productType"
                valuePropName="value"
              >
                <Checkbox.Group>
                  <Space direction="vertical">
                    {Object.entries(SERVICE_TYPE).map(entry => (
                      <Checkbox value={entry[0]}>{entry[1]}</Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <>
                    {t('pages.locationForm.FYI.message')}
                    <span className="text-red">&nbsp;*</span>
                  </>
                }
                rules={[
                  {
                    required: true
                  }
                ]}
                requiredMark="optional"
                name="message"
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('pages.locationForm.FYI.date-from')}
                name="dateFrom"
                getValueProps={value => ({
                  value: value ? dayjs(value) : ''
                })}
              >
                <DatePicker
                  disabledDate={current => {
                    const dateTo = form.getFieldValue('dateTo');
                    return (
                      dateTo && current && current > dayjs(dateTo).endOf('day')
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('pages.locationForm.FYI.date-to')}
                name="dateTo"
                getValueProps={value => ({
                  value: value ? dayjs(value) : ''
                })}
              >
                <DatePicker
                  disabledDate={current => {
                    const dateFrom = form.getFieldValue('dateFrom');
                    return current && current < dayjs(dateFrom).startOf('day');
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[40, 16]}>
            <Col span={24}>
              <Space size="small">
                <Button onClick={onCancel} type="default">
                  {t('buttons.cancel')}
                </Button>
                <SaveButton icon={false} {...saveButtonProps} />
              </Space>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
