export enum ESyncType {
  SUPPLIER = 'Supplier',
  TOUR = 'Tour',
  TRANSPORTATION = 'Transportation',
  ACCOMMODATION = 'Accommodation',
  CRUISE_ACCOMMODATION = 'Cruise Accommodation',
  LOCATION = 'Location',
  CONVERSION_RATE = 'Conversion Rate',
  EXCHANGE_RATE = 'Exchange Rates'
}
