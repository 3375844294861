import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete,
  Box,
  Button,
  FormGroup,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { Select } from '@pankod/refine-antd';
import { useNotification } from '@pankod/refine-core';
import { SYNC_LIST_TYPE } from 'constants/syncMenu';
import { ESyncType } from 'constants/syncType';
import {
  SYNC_ACCOMMODATIONS,
  SYNC_CRUISE_ACCOMMODATION,
  SYNC_LOCATIONS,
  SYNC_SUPPLIER,
  SYNC_TOUR,
  SYNC_TRANSPORTATIONS
} from 'data-provider/graphql';
import { GET_ORGANIZATIONS, GET_PROVIDER_SERVERS } from 'graphql-queries';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GraphqlService } from 'services/graphql';
import { ILabel } from 'types';
import { ISyncType } from 'types/manualSync';
import { Organizations, ProviderServers } from 'utils/hasura';
import { SyncStatusList } from '../SyncStatuses/index';
import { SyncItemLabel } from './SyncItemLabel/index';

export const SyncContent: React.FC = () => {
  const { t } = useTranslation();
  const { open } = useNotification();
  const { client: graphqlClient } = GraphqlService;
  const [srcProviders, setSrcProviders] = useState<ILabel[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<ILabel[]>([]);
  const [srcOrganizations, setSrcOrganizations] = useState<ILabel[]>([]);
  const [selectedOrgs, setSelectedOrgs] = useState<ILabel[]>([]);
  const [syncType, setSyncType] = useState<ISyncType>(SYNC_LIST_TYPE[0]);
  const [isShowServer, setShowServer] = useState<boolean>(false);
  const [isBusy, setBusy] = useState<boolean>(false);
  const [result, setResult] = useState('');

  const getServerProviders = async () => {
    const res: {
      provider_servers: ProviderServers[];
    } = await graphqlClient.request(GET_PROVIDER_SERVERS);
    const providerServers: ILabel[] = res.provider_servers.map(
      ({ id, country_name: countryName }) => ({
        id,
        value: countryName || ''
      })
    );
    setSrcProviders(providerServers);
  };

  const getOrganizations = async () => {
    const res: {
      organizations: Organizations[];
    } = await graphqlClient.request(GET_ORGANIZATIONS);

    const data: ILabel[] = res.organizations.map(({ id, name }) => ({
      id,
      value: name || ''
    }));
    setSrcOrganizations(data || []);
  };

  useEffect(() => {
    getServerProviders().catch(
      error => open && open({ message: error.message, type: 'error' })
    );
    getOrganizations().catch(
      error => open && open({ message: error.message, type: 'error' })
    );
    setResult('');
  }, []);

  useEffect(() => {
    setShowServer(
      [
        ESyncType.SUPPLIER,
        ESyncType.TOUR,
        ESyncType.TRANSPORTATION,
        ESyncType.ACCOMMODATION,
        ESyncType.CRUISE_ACCOMMODATION,
        ESyncType.LOCATION,
        ESyncType.CONVERSION_RATE,
        ESyncType.EXCHANGE_RATE
      ].includes(syncType.value as ESyncType)
    );
    setResult('');
  }, [syncType]);

  const handleStartSync = async () => {
    try {
      setResult('');
      setBusy(true);
      const filter = {
        provider_servers: selectedProviders.map(({ id }) => id),
        organizations: selectedOrgs.map(({ id }) => id)
      };

      switch (syncType.value) {
        case ESyncType.SUPPLIER:
          await graphqlClient.request(SYNC_SUPPLIER, {
            ...filter
          });
          break;
        case ESyncType.ACCOMMODATION:
          await graphqlClient.request(SYNC_ACCOMMODATIONS, {
            ...filter
          });
          break;
        case ESyncType.CRUISE_ACCOMMODATION:
          await graphqlClient.request(SYNC_CRUISE_ACCOMMODATION, {
            ...filter
          });
          break;
        case ESyncType.TOUR:
          await graphqlClient.request(SYNC_TOUR, {
            ...filter
          });
          break;
        case ESyncType.TRANSPORTATION:
          await graphqlClient.request(SYNC_TRANSPORTATIONS, {
            ...filter
          });
          break;
        case ESyncType.LOCATION:
          await graphqlClient.request(SYNC_LOCATIONS, {
            ...filter
          });
          break;
        default:
          throw new Error('Unknown sync type!');
      }
      setResult(t('common:sync.result-ok'));
    } catch {
      setResult(t('common:sync.result-ng'));
    } finally {
      setBusy(false);
    }
  };

  return (
    <div className="py-7 mx-auto border bg-white !rounded-lg border-white">
      <Box className="modal-wrapper px-7 pb-8">
        <Typography className="font-medium" variant="h5" component="p">
          {t('common:sync.title')}
        </Typography>
        <Grid container className="mt-6">
          <Grid item xs={12} className="!mt-8">
            <FormGroup>
              <SyncItemLabel label={t('common:sync.product-type')} />
              <Autocomplete
                fullWidth
                id="product-type"
                size="small"
                options={SYNC_LIST_TYPE}
                value={syncType}
                onChange={(_e, value) => {
                  if (value) setSyncType(value);
                }}
                getOptionLabel={option => option?.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    className="border-solid border-gray-700"
                    placeholder={t('common:placeholder.select')}
                    InputLabelProps={{ shrink: false }}
                  />
                )}
                clearIcon={<ClearIcon fontSize="small" />}
              />
            </FormGroup>
          </Grid>

          {isShowServer && (
            <Grid item xs={12} className="!mt-8">
              <FormGroup>
                <SyncItemLabel label={t('common:sync.select-countries')} />
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  value={selectedProviders}
                  placeholder="Please select countries"
                  options={srcProviders}
                  onChange={(e: any) => {
                    if (Array.isArray(e) && e.length > 0) {
                      setSelectedProviders(
                        srcProviders.filter(server => e.includes(server.value))
                      );
                    }
                  }}
                  onClear={() => {
                    setSelectedProviders([]);
                  }}
                />
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedProviders(srcProviders);
                  }}
                  onKeyDown={(event: any) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                      setSelectedProviders(srcProviders);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {t('common:sync.select-all')}
                </span>
              </FormGroup>
            </Grid>
          )}

          {isShowServer && (
            <Grid item xs={12} className="!mt-8">
              <FormGroup>
                <SyncItemLabel label={t('common:sync.select-organizations')} />
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  value={selectedOrgs}
                  placeholder="Please select organizations"
                  options={srcOrganizations}
                  onChange={(e: any) => {
                    if (Array.isArray(e) && e.length > 0) {
                      setSelectedOrgs(
                        srcOrganizations.filter(org => e.includes(org.value))
                      );
                    }
                  }}
                  onClear={() => {
                    setSelectedOrgs([]);
                  }}
                />
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedOrgs(srcOrganizations);
                  }}
                  onKeyDown={(event: any) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                      setSelectedOrgs(srcOrganizations);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {t('common:sync.select-all')}
                </span>
              </FormGroup>
            </Grid>
          )}
        </Grid>
      </Box>

      {result && (
        <Box className="flex justify-center mt-6 px-7">
          <span>{result}</span>
          <br />
        </Box>
      )}

      <Box className="flex justify-end mt-6 px-7">
        <Button
          disabled={
            isBusy ||
            ((selectedProviders.length === 0 || selectedOrgs.length === 0) &&
              syncType?.value !== ESyncType.EXCHANGE_RATE)
          }
          onClick={handleStartSync}
          variant="contained"
          className="text-xs font-normal h-8 opacity-60"
        >
          {t('common:buttons.sync')}
        </Button>
      </Box>
      <SyncStatusList />
    </div>
  );
};
