import { Switch, useTable } from '@pankod/refine-antd';
import { CrudFilters, HttpError, useUpdate } from '@pankod/refine-core';
import { ActionCell } from 'components/ActionCell';
import { TitleFilter } from 'components/TitleFilter';
import { ListPageLayout } from 'components/layout/ListPageLayout';
import { ServiceType } from 'constants/common';
import { useCountries } from 'hooks/useCountries';
import { useSyncStatusSubscription } from 'hooks/useSyncStatusSubscription';
import { useTranslation } from 'react-i18next';
import { buildCountrySelectOptions } from 'utils/buildCountrySelectOptions';
import { buildFilterItem } from 'utils/buildFilterItem';
import { buildTableColumn } from 'utils/buildTableColumn';
import { Products } from 'utils/hasura';

interface ProductListProps {
  resource: 'tour' | 'transportation';
}

export const ProductList: React.FC<ProductListProps> = ({
  resource
}: ProductListProps) => {
  const { mutate } = useUpdate<Products>();
  const { t } = useTranslation();
  const countries = useCountries();

  const { syncStatusData } = useSyncStatusSubscription({ syncType: resource });

  const fields = [
    'id',
    'product_code',
    'name',
    'name_xhr',
    'product_type',
    'class_description',
    'address4',
    'address5',
    'locality',
    'is_active',
    'is_recommended',
    {
      [resource]: ['guide', 'id']
    }
  ];

  const { tableProps, searchFormProps } = useTable<
    Products,
    HttpError,
    {
      product_code: string;
      class_description: string;
      name: string;
      name_xhr: string;
      address4: string;
      address5: string;
      locality: string;
      guide: string;
    }
  >({
    resource: 'products',
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'product_type',
        operator: 'eq',
        value:
          resource === 'transportation'
            ? ServiceType.TRANSPORTATION
            : ServiceType.TOUR
      }
    ],
    onSearch: params => {
      const filters: CrudFilters = [];
      const {
        product_code: productCode,
        name,
        name_xhr: nameAlternative,
        address4,
        address5,
        locality,
        guide,
        class_description: classDescription
      } = params;

      filters.push(
        buildFilterItem('product_code', 'contains', productCode),
        buildFilterItem('name', 'contains', name),
        buildFilterItem('name_xhr', 'contains', nameAlternative),
        buildFilterItem('address4', 'contains', address4),

        // FIXME: should use country code field instead of address5 field
        // when the country code is available in products table
        buildFilterItem('address5', 'contains', address5),
        buildFilterItem('locality', 'contains', locality),
        buildFilterItem(`${resource}.guide`, 'contains', guide),
        buildFilterItem('class_description', 'contains', classDescription)
      );

      return filters;
    },
    metaData: {
      fields
    }
  });

  tableProps.columns = [
    buildTableColumn(
      <TitleFilter
        name="product_code"
        title={t('pages.productList.productCode')}
        filterType="string"
      />,
      'product_code'
    ),
    buildTableColumn(
      <TitleFilter
        name="name"
        title={
          resource === 'tour'
            ? t('pages.productList.tour-name')
            : t('pages.productList.transportation-name')
        }
      />,
      'name'
    ),
    buildTableColumn(
      <TitleFilter
        name="name_xhr"
        title={t('pages.productList.nameAlternative')}
      />,
      'name_xhr'
    ),
    buildTableColumn(
      <TitleFilter
        name="class_description"
        title={t('pages.productList.classDescription')}
      />,
      'class_description'
    ),
    buildTableColumn(
      <TitleFilter name="locality" title={t('pages.productList.locality')} />,
      'locality'
    ),
    buildTableColumn(
      <TitleFilter name="address4" title={t('pages.productList.address4')} />,
      'address4'
    ),
    buildTableColumn(
      <TitleFilter
        name="address5"
        title={t('pages.productList.address5')}
        filterType="dropdown"
        options={buildCountrySelectOptions(countries)}
      />,
      'address5'
    ),
    buildTableColumn(
      <TitleFilter name="guide" title={t('pages.productList.guide')} />,
      [resource, 'guide']
    ),
    buildTableColumn(
      t('recommendation'),
      'is_recommended',
      true,
      (value, product) => (
        <Switch
          checked={!!value}
          onChange={checked => {
            mutate({
              resource: 'products',
              id: product.id,
              values: {
                is_recommended: checked
              }
            });
          }}
        />
      )
    ),
    buildTableColumn(
      t('pages.productList.active'),
      'is_active',
      true,
      (value, product) => (
        <Switch
          checked={!!value}
          onChange={checked => {
            mutate({
              resource: 'products',
              id: product.id,
              values: {
                is_active: checked
              }
            });
          }}
        />
      )
    ),
    buildTableColumn(t('actions'), 'id', false, (_, record) => (
      <ActionCell
        id={record.id!}
        deleteResource="products"
        actions={['edit']}
      />
    ))
  ];

  return (
    <ListPageLayout
      pageHeaderProps={{
        extra: syncStatusData && t(syncStatusData.messageKey)
      }}
      tableProps={tableProps}
      searchFormProps={searchFormProps}
    />
  );
};
