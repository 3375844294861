import { ISyncType } from 'types/manualSync';
import { ESyncType } from './syncType';

export const SYNC_LIST_TYPE: ISyncType[] = [
  {
    id: 1,
    value: ESyncType.SUPPLIER
  },
  {
    id: 2,
    value: ESyncType.ACCOMMODATION
  },
  {
    id: 3,
    value: ESyncType.TOUR
  },
  {
    id: 4,
    value: ESyncType.TRANSPORTATION
  },
  {
    id: 5,
    value: ESyncType.LOCATION
  },
  {
    id: 6,
    value: ESyncType.CRUISE_ACCOMMODATION
  }
];
