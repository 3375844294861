import { Upload, UploadFile } from '@pankod/refine-antd';

import { Image } from 'models/imageUpload';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export interface ImageUploadProps {
  value?: UploadFile<Image>[];
  onChange?: Function;
}

export interface UploadChange {
  fileList: UploadFile[];
  file?: UploadFile;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  value = [],
  onChange = () => {}
}: ImageUploadProps) => {
  const images = [...value];
  const { t } = useTranslation();

  const handleChange = ({ fileList }: UploadChange) => {
    // Check deleted item and push again with status 'removed'
    images.forEach((image: UploadFile) => {
      const idx = fileList.find((item: UploadFile) => item.uid === image.uid);
      if (!idx && !image.lastModified) {
        fileList.push(image);
      }
    });
    onChange(fileList);
  };

  const uploadButton = (
    <div>
      <div className={styles.uploadButton}>
        {t('components.imageUpload.upload')}
      </div>
    </div>
  );
  return (
    <Upload
      listType="picture-card"
      beforeUpload={() => false}
      fileList={value}
      onChange={handleChange}
      multiple
      showUploadList={{ showPreviewIcon: false }}
    >
      {uploadButton}
    </Upload>
  );
};

ImageUpload.defaultProps = {
  value: [],
  onChange: () => {}
};
