import { Space, EditButton, DeleteButton } from '@pankod/refine-antd';
import { useParams } from 'react-router-dom';

export interface ActionCellProps {
  id: number;
  actions?: ('edit' | 'delete')[];
  editResource?: string;
  deleteResource?: string;
}

export const ActionCell: React.FC<ActionCellProps> = ({
  id,
  actions,
  editResource,
  deleteResource
}: ActionCellProps) => {
  const { resource } = useParams();
  const allowAction = (action: 'edit' | 'delete'): boolean =>
    !!actions?.includes(action);

  return (
    <Space>
      {allowAction('edit') && (
        <EditButton
          resourceName={editResource || resource}
          size="small"
          recordItemId={id}
          hideText
        />
      )}

      {allowAction('delete') && (
        <DeleteButton
          resourceName={deleteResource || resource}
          size="small"
          recordItemId={id}
          hideText
        />
      )}
    </Space>
  );
};

ActionCell.defaultProps = {
  actions: ['edit', 'delete'],
  editResource: undefined,
  deleteResource: undefined
};
