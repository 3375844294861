import { gql } from 'graphql-request';
import { GoogleLoginResponse } from 'react-google-login';

import {
  LoginGoogleInput,
  LoginInput,
  LoginLocalInput,
  LoginPassword,
  LoginRequest
} from 'models/auth';
import { GraphqlService } from 'services/graphql';

const { client: graphqlClient } = GraphqlService;

const LOGIN = gql`
  mutation login(
    $loginType: LoginType!
    $accessToken: String
    $email: String
    $password: String
  ) {
    login(
      login_type: $loginType
      access_token: $accessToken
      email: $email
      password: $password
    ) {
      token
    }
  }
`;

export const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';

class AuthService {
  public login(variables: LoginRequest) {
    return graphqlClient.request(LOGIN, variables);
  }

  public buildVariables(input: LoginInput): LoginRequest {
    const { loginType } = input;

    let variables = {
      loginType
    } as LoginRequest;

    if (loginType === 'PASSWORD' && input.local) {
      const localInfo = this.getLoginLocalVariables(input.local);

      if (localInfo) {
        variables = {
          ...variables,
          ...localInfo
        };
      }
    }

    if (loginType === 'GOOGLE' && input.google) {
      const googleInfo = this.getLoginGoogleVariables(input.google);

      if (googleInfo) {
        variables = {
          ...variables,
          ...googleInfo
        };
      }
    }

    return variables;
  }

  private getLoginLocalVariables(local: LoginPassword): LoginLocalInput | null {
    if (!local || !local.email || !local.password) {
      return null;
    }

    return {
      email: local.email,
      password: local.password
    };
  }

  private getLoginGoogleVariables(
    input: GoogleLoginResponse
  ): LoginGoogleInput | null {
    if (!input || !input?.profileObj?.email || !input?.accessToken) {
      return null;
    }

    return {
      email: input.profileObj.email,
      accessToken: input.accessToken
    };
  }
}

const singleton = new AuthService();

export { singleton as AuthService };
