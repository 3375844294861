import { Option } from '@pankod/refine-core';

import { AMENITIES } from 'constants/amenities';

export function buildAmenityOptions(): Option[] {
  return AMENITIES.map(amenity => ({
    value: amenity.code,
    label: amenity.description
  }));
}
