import { useSelect, useTable } from '@pankod/refine-antd';
import { CrudFilters, HttpError } from '@pankod/refine-core';
import { useTranslation } from 'react-i18next';
import { Organizations, Users } from 'utils/hasura';

import { buildTableColumn } from 'utils';

import { ActionCell } from 'components/ActionCell';
import { TitleFilter } from 'components/TitleFilter';
import { ListPageLayout } from 'components/layout/ListPageLayout';
import { buildFilterItem } from 'utils/buildFilterItem';
import { buildUserRoleOptions } from 'utils/buildUserRoleOptions';

const fields = [
  'id',
  {
    organization: ['name']
  },
  'title',
  'first_name',
  'last_name',
  'email',
  'role',
  'is_activated'
];

export const UserList: React.FC<any> = () => {
  const { t } = useTranslation();

  const { tableProps, searchFormProps } = useTable<
    Users,
    HttpError,
    {
      organization_id: string;
      title: string;
      first_name: string;
      last_name: string;
      email: string;
      role: string;
    }
  >({
    initialSorter: [
      {
        field: 'email',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted_at',
        operator: 'null',
        value: true
      }
    ],
    onSearch: params => {
      const filters: CrudFilters = [];
      const {
        organization_id: organizationId,
        first_name: firstName,
        title,
        last_name: lastName,
        email,
        role
      } = params;

      filters.push(
        buildFilterItem('organization_id', 'eq', organizationId),
        buildFilterItem('title', 'contains', title),
        buildFilterItem('first_name', 'contains', firstName),
        buildFilterItem('last_name', 'contains', lastName),
        buildFilterItem('email', 'contains', email),
        buildFilterItem('role', 'eq', role)
      );

      return filters;
    },
    metaData: {
      fields
    }
  });

  const { selectProps: selectOrganizationsProps } = useSelect<Organizations>({
    resource: 'organizations',
    optionLabel: 'name',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'name']
    },
    sort: [
      {
        field: 'name',
        order: 'asc'
      }
    ]
  });

  tableProps.columns = [
    buildTableColumn(
      <TitleFilter
        name="organization_id"
        title={t('pages.users.organization')}
        filterType="dropdown"
        selectProps={selectOrganizationsProps}
      />,
      'organization_id',
      true,
      (_, record) => record.organization.name
    ),
    buildTableColumn(
      <TitleFilter name="title" title={t('pages.users.title')} />,
      'title'
    ),
    buildTableColumn(
      <TitleFilter name="first_name" title={t('pages.users.firstName')} />,
      'first_name'
    ),
    buildTableColumn(
      <TitleFilter name="last_name" title={t('pages.users.lastName')} />,
      'last_name'
    ),
    buildTableColumn(
      <TitleFilter name="email" title={t('pages.users.email')} />,
      'email'
    ),
    buildTableColumn(
      <TitleFilter
        name="role"
        title={t('pages.users.role')}
        filterType="dropdown"
        options={buildUserRoleOptions(t)}
      />,
      'role',
      true,
      (_, record) => t(`pages.users.roles.${record.role.toLocaleLowerCase()}`)
    ),
    buildTableColumn('', 'id', false, (_, record) => (
      <ActionCell id={record.id} />
    ))
  ];

  return (
    <ListPageLayout tableProps={tableProps} searchFormProps={searchFormProps} />
  );
};
