import { UserRole } from 'constants/common';
import { TFunction } from 'react-i18next';
import { buildUserRoleOptions } from './buildUserRoleOptions';

export function buildAssignableRoleOptions(t: TFunction, currentRole: string) {
  const roleOptions = buildUserRoleOptions(t);

  if (currentRole === UserRole.SUPER_ADMIN) {
    return roleOptions;
  }

  if (currentRole === UserRole.TRAVEL_AGENCY_MANAGER) {
    const assignableRoles = [
      UserRole.TRAVEL_AGENCY_MANAGER,
      UserRole.TRAVEL_AGENCY_SPECIALIST,
      UserRole.TRAVEL_AGENT
    ];

    return roleOptions.filter(
      option => assignableRoles.includes(option.value as UserRole)
      // eslint-disable-next-line function-paren-newline
    );
  }

  return [];
}
