/**
 * Transform filter format
 * From { parent.child: 'value' } to { parent: { child: 'value' } }
 */
export function generateFilter(
  where: Record<string, any>
): Record<string, any> | null {
  if (!where) {
    return null;
  }

  const filter = Object.keys(where).reduce((previous: any, current) => {
    const fields = current.split('.');
    const lastIndex = fields.length - 1;
    const value = { ...previous };

    let lastFieldValue = value;

    fields.forEach((field, index) => {
      if (index === lastIndex) {
        lastFieldValue[field] = where[current];

        return;
      }

      Object.assign(lastFieldValue, { [field]: lastFieldValue[field] || {} });
      lastFieldValue = lastFieldValue[field];
    });

    return value;
  }, {});

  return filter;
}
